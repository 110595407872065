// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id:
    "eu-central-1:b66163a5-43c5-4ebe-9394-aae9c0353427",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_C5awbP7Ps",
  aws_user_pools_web_client_id: "6ajm2m34gkste3vk4bqrcohdie",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://5ehh6x6f2vawrelmkl2uhmphje.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cloud_logic_custom: [
    {
      name: "quirionrest",
      endpoint:
        "https://nx03jy1jx2.execute-api.eu-central-1.amazonaws.com/test",
      region: "eu-central-1"
    }
  ],
  aws_mobile_analytics_app_id: "901cb8da45d94967b459a926652497b3",
  aws_mobile_analytics_app_region: "eu-central-1",
  aws_user_files_s3_bucket: "quirion-postfach-test",
  aws_user_files_s3_bucket_region: "eu-central-1"
};

export default awsmobile;
