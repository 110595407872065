import React from "react";
import helper_global from "../utils/helper_global.js";

const Footer = props => {
  return (
    <div className="footer">
      <ul>
        <li
          className="link shy"
          onClick={() => helper_global.externalLink("quirion_datenschutz")}
          title={helper_global.externalLink("quirion_datenschutz", true)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Datenschutz
        </li>
        <li
          className="link shy"
          onClick={() => helper_global.externalLink("quirion_impressum")}
          title={helper_global.externalLink("quirion_impressum", true)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Impressum
        </li>
      </ul>
    </div>
  );
};

export default Footer;
