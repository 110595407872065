export default {
  questionVersion: "2019-11-04",
  client_type: {
    quirion: {
      advisor: 100,
      min_amount: 5000,
      min_amount_savinsplan: 100
    },
    quirion_premium: {
      advisor: 101,
      min_amount: 10000,
      min_amount_savinsplan: 500
    },
    xing: {
      advisor: 200,
      min_amount: 5000,
      min_amount_savinsplan: 100
    },
    xing_premium: {
      advisor: 201,
      min_amount: 10000,
      min_amount_savinsplan: 500
    },
    savedo: {
      advisor: 300,
      min_amount: 1000,
      min_amount_savinsplan: 10
    },
    savedo_premium: {
      advisor: 301,
      min_amount: 10000,
      min_amount_savinsplan: 100
    }
  }
};
