import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import axios from 'axios'

class Messages extends Component {
  getGreenMessage(innertHtml, index) {
    return (
      <div key={index}>
        <div className="splitter"></div>
        <div className="message">
          <div
            className="info"
            dangerouslySetInnerHTML={{ __html: innertHtml }}
          />
          <div className="controls">
            <span
              className="button"
              onClick={this.confirmMessage.bind(null, index)}
            >
              OK
            </span>
          </div>
        </div>
      </div>
    );
  }

  getWarningMessage(innertHtml, index) {
    return (
      <div key={index}>
        <div className="splitter"></div>
        <div className="message attention">
          <div
            className="info"
            dangerouslySetInnerHTML={{ __html: innertHtml }}
          />
          <div className="controls">
            <span
              className="button"
              onClick={this.confirmMessage.bind(null, index)}
            >
              OK
            </span>
          </div>
        </div>
      </div>
    );
  }

  confirmMessage = index => {
    const { messages } = this.props;
    messages.splice(index, 1);
    this.setState({ messages: messages });
  };

  render() {
    //console.log(this.props.location);
    const { user, messages } = this.props;

    let messageList = "";

    if (messages.length > 0) {
      messageList = messages.length
        ? messages.map((message, index) => {
            if (message === "activation done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihr Konto ist nun aktiviert. Ab sofort können Sie sich mit Ihrer E-Mail-Adresse anmelden.",
                index
              );
            } else if (message === "activation unauthorized_error") {
              if (user.loaded !== true) {
                return this.getWarningMessage(
                  "<strong>Achtung</strong> Der Aktivierungslink funktioniert nur einmal, bitte melden Sie sich an!",
                  index
                );
              }
            } else if (message === "delete product done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihr Auftrag wird nun bearbeitet.",
                index
              );
            } else if (message === "new_product done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihre neue Strategie wird nun angelegt.",
                index
              );
            } else if (message === "money up done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihre Aufstockung wird nun verarbeitet.",
                index
              );
            } else if (message === "money down done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihre Entnahme wird nun verarbeitet.",
                index
              );
            } else if (message === "money down reference done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihre Auszahlung wird nun verarbeitet.",
                index
              );
            } else if (message === "create savingsplan done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihr Sparplan wurde erfolgreich angelegt.",
                index
              );
            } else if (message === "delete savingsplan done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihr Sparplan wurde erfolgreich gelöscht.",
                index
              );
            } else if (message === "update savingsplan done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihr Sparplan wurde erfolgreich aktualisiert.",
                index
              );
            } else if (message === "person_data_change done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Ihre persönlichen Daten wurden erfolgreich aktualisiert.",
                index
              );
            } else if (message === "strategyChange done") {
              return this.getGreenMessage(
                "<strong>Vielen Dank!</strong> Wir bearbeiten Ihren Strategiewechsel.",
                index
              );
            } else if (
              (message.indexOf("Fehler") !== -1)
              || (message.indexOf("Error") !== -1)
              || (message.indexOf("error") !== -1)
              || (message.indexOf("Problem") !== -1)
              || (message.indexOf("problem") !== -1)
              || (message.indexOf("fehlgeschlagen") !== -1)
            ) {
              return this.getWarningMessage(message, index);
            } else {
              /*
                    else if(message === 'login message1'){
                        return this.getWarningMessage('Unter „Kontoumsätze" finden Sie alle Umsätze ab dem 03.12.2019. Ältere Umsätze finden Sie wie gewohnt in Ihren Kontoauszügen.',index)
                    }
                    else if(message === 'login message2'){
                        return this.getWarningMessage('Bei der quirion-App kommt es gelegentlich zu Ausfällen, diese werden jedoch zeitnah behoben.',index)
                    }
                    else if(message === 'login message3'){
                        return this.getWarningMessage('Sofern Sie einen Freistellungsauftrag für 2019 erteilt haben, wird dieser ab dem 01.01.2020 unter „persönliche Angaben“ (Ihr Name) in der Hauptnavigation des Onlinebankings zu finden sein. Wenn Sie Fragen zu Ihrem erteilten Freistellungsauftrag haben, senden Sie diese bitte per E-Mail an info@quirion.de. Den Saldo Ihres Freistellungsauftrages finden Sie in Ihrer Postbox in der letzten Erträgnisabrechnung auf Seite 2.',index)
                    }
                    else if(message === 'login message4'){
                        return this.getWarningMessage('Aufgrund der aktuell hohen Nachfrage kann es zu Verzögerungen bei der Eröffnung von Konten und Strategien kommen. Wir bitten das zu entschuldigen.',index)
                    }
                    */
              return this.getGreenMessage(message, index);
            }
            return "";
          })
        : "";
    }

    //<li className="hidden"><NavLink to="/depots" className="header__navigation-link" >Übersicht</NavLink></li>
    if (messages.length > 0) {
      return <div className="messages">{messageList}</div>;
    } else {
      return "";
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    messages: state.messages
  };
};

export default withRouter(connect(mapStateToProps)(Messages));
