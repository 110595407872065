import React, { Suspense, lazy } from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
  BrowserRouter as Router
} from "react-router-dom";
import { Auth } from "aws-amplify";
import Header from "./Header";
import Footer from "./Footer";
import Messages from "./Messages";
import {
  storeLastLocation,
  updateUserLastActivity
} from "./../actions/userActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Forgot = lazy(() => import(`./authentication/Forgot`));
const Home = lazy(() => import(`./Home`));
const Login = lazy(() => import(`./authentication/Login`));
const Logout = lazy(() => import(`./authentication/Logout`));
const Robo = lazy(() => import(`./Robo`));
const AddStep1 = lazy(() => import(`./add/Step_1`));
const AddStep2 = lazy(() => import(`./add/Step_2`));
const AddStep3 = lazy(() => import(`./add/Step_3`));
const AddStep4 = lazy(() => import(`./add/Step_4`));
const AddJoinedStep1 = lazy(() => import(`./add/Step_1_joined`));
const AddJoinedStep2 = lazy(() => import(`./add/Step_2_joined`));
const AddJoinedStep3 = lazy(() => import(`./add/Step_3_joined`));
const AddJoinedStep4 = lazy(() => import(`./add/Step_4_joined`));
const AddChildStep1 = lazy(() => import(`./add/Step_1_child`));
const AddChildStep2 = lazy(() => import(`./add/Step_2_child`));
const AddChildStep3 = lazy(() => import(`./add/Step_3_child`));
const AddChildStep4 = lazy(() => import(`./add/Step_4_child`));
const SingleStep1 = lazy(() => import(`./registration/single/Step_1`));
const SingleStep2 = lazy(() => import(`./registration/single/Step_2`));
const SingleStep3 = lazy(() => import(`./registration/single/Step_3`));
const SingleStep4 = lazy(() => import(`./registration/single/Step_4`));
const SingleStep5 = lazy(() => import(`./registration/single/Step_5`));
const JoinedStep1 = lazy(() => import(`./registration/joined/Step_1`));
const JoinedStep2 = lazy(() => import(`./registration/joined/Step_2`));
const JoinedStep3 = lazy(() => import(`./registration/joined/Step_3`));
const JoinedStep4 = lazy(() => import(`./registration/joined/Step_4`));
const JoinedStep5 = lazy(() => import(`./registration/joined/Step_5`));
const ChildStep1 = lazy(() => import(`./registration/child/Step_1`));
const ChildStep2 = lazy(() => import(`./registration/child/Step_2`));
const ChildStep3 = lazy(() => import(`./registration/child/Step_3`));
const ChildStep4 = lazy(() => import(`./registration/child/Step_4`));
const ChildStep5 = lazy(() => import(`./registration/child/Step_5`));
const Money_in = lazy(() => import(`./dialogs/Money_in`));
const Money_out = lazy(() => import(`./dialogs/Money_out`));
const Money_out_reference = lazy(() => import(`./dialogs/Money_out_reference`));
const StrategyChange = lazy(() => import(`./dialogs/StrategyChange`));
const Setup = lazy(() => import(`./dialogs/Setup`));
const SetupPersonalData = lazy(() => import(`./dialogs/setup/PersonalData`));
const SetupTax = lazy(() => import(`./dialogs/setup/Tax.js`));
const SetupAccounts = lazy(() => import(`./dialogs/setup/Accounts`));
const SetupCashInOut = lazy(() => import(`./dialogs/setup/Cash_in_out`));
const Savingsplan = lazy(() => import(`./dialogs/setup/Savingsplan`));
const SetupAuthorisation = lazy(() => import(`./dialogs/setup/Authorisation`));
const SetupWphg = lazy(() => import(`./dialogs/setup/Wphg`));
const SetupPassword = lazy(() => import(`./dialogs/setup/Password`));
const SetupExemptionOrder = lazy(() =>
  import(`./dialogs/setup/Exemption_order`)
);
const PostBox = lazy(() => import(`./dialogs/PostBox`));
const Order = lazy(() => import(`./dialogs/Order`));
const UserActivate = lazy(() => import(`./UserActivate`));
const DeleteProduct = lazy(() => import(`./viewDeleteProduct`));

class PrivateRouteBase extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    prevPath: "",
    isAdmin: false
  };
  componentDidMount() {
    this.authenticate();
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          //console.log('user: ', user)
        })
        .catch(() => {
          if (this.state.isAuthenticated)
            this.setState({ isAuthenticated: false });
        });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log('updateUserLastActivity')
    if (this.props.location !== nextProps.location) {
      this.setState({ prevPath: this.props.location });
      this.props.storeLastLocation(this.props.location);
      this.props.updateUserLastActivity();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }
  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.setState({ loaded: true, isAuthenticated: true });
      })
      .catch(() => this.props.history.push("/login/"));
  }
  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login/"
              }}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    userStatus: state.userStatus
  };
};

PrivateRouteBase.propTypes = {
  storeLastLocation: PropTypes.func.isRequired,
  updateUserLastActivity: PropTypes.func.isRequired
};

const PrivateRoute = withRouter(
  connect(mapStateToProps, { storeLastLocation, updateUserLastActivity })(
    PrivateRouteBase
  )
);

const Routes = () => (
  <Router>
    <div>
      <Header />
      <Messages />
      <div id="content">
        <div className="container maincontent">
          <Suspense fallback={<div>...</div>}>
            <Switch>
              <Route path="/login/" exact component={Login} />
              <Route path="/robo/" component={Robo} />
              {/* Probably DEPRECATED. client_type (e.g. quirion, quirion_premium, xing, xing_premium, savedo, savedo_premium) refers to referral marketing campaigns */}
              <Route path="/robo/:client_type" component={Robo} />
              <Route path="/forgot_password" exact component={Forgot} />
              <Route path="/logout/" exact component={Logout} />
              <Route path="/logout/byInacvtivity" exact component={Logout} />
              <Route
                path="/user/activate/:username/:code/"
                exact
                component={UserActivate}
              />
              <PrivateRoute path="/" exact component={Home} />
              <PrivateRoute path="/money_in/:id" exact component={Money_in} />
              <PrivateRoute path="/money_out/:id" exact component={Money_out} />
              <PrivateRoute
                path="/money_out_reference/:id"
                exact
                component={Money_out_reference}
              />
              <PrivateRoute path="/add/step_1/" exact component={AddStep1} />
              <PrivateRoute path="/add/step_2/" exact component={AddStep2} />
              <PrivateRoute path="/add/step_3/" exact component={AddStep3} />
              <PrivateRoute path="/add/step_4/" exact component={AddStep4} />
              <PrivateRoute
                path="/add/joined/step_1/:id"
                exact
                component={AddJoinedStep1}
              />
              <PrivateRoute
                path="/add/joined/step_2/:id"
                exact
                component={AddJoinedStep2}
              />
              <PrivateRoute
                path="/add/joined/step_3/:id"
                exact
                component={AddJoinedStep3}
              />
              <PrivateRoute
                path="/add/joined/step_4/:id"
                exact
                component={AddJoinedStep4}
              />
              <PrivateRoute
                path="/add/child/step_1/:id"
                exact
                component={AddChildStep1}
              />
              <PrivateRoute
                path="/add/child/step_2/:id"
                exact
                component={AddChildStep2}
              />
              <PrivateRoute
                path="/add/child/step_3/:id"
                exact
                component={AddChildStep3}
              />
              <PrivateRoute
                path="/add/child/step_4/:id"
                exact
                component={AddChildStep4}
              />
              <PrivateRoute
                path="/registration/single/step_1/:id"
                exact
                component={SingleStep1}
              />
              <PrivateRoute
                path="/registration/single/step_2/:id"
                exact
                component={SingleStep2}
              />
              <PrivateRoute
                path="/registration/single/step_3/:id"
                exact
                component={SingleStep3}
              />
              <PrivateRoute
                path="/registration/single/step_4/:id"
                exact
                component={SingleStep4}
              />
              <PrivateRoute
                path="/registration/single/step_5/:id"
                exact
                component={SingleStep5}
              />
              <PrivateRoute
                path="/registration/joined/step_1/:id"
                exact
                component={JoinedStep1}
              />
              <PrivateRoute
                path="/registration/joined/step_2/:id"
                exact
                component={JoinedStep2}
              />
              <PrivateRoute
                path="/registration/joined/step_3/:id"
                exact
                component={JoinedStep3}
              />
              <PrivateRoute
                path="/registration/joined/step_4/:id"
                exact
                component={JoinedStep4}
              />
              <PrivateRoute
                path="/registration/joined/step_5/:id"
                exact
                component={JoinedStep5}
              />
              <PrivateRoute
                path="/registration/child/step_1/:id"
                exact
                component={ChildStep1}
              />
              <PrivateRoute
                path="/registration/child/step_2/:id"
                exact
                component={ChildStep2}
              />
              <PrivateRoute
                path="/registration/child/step_3/:id"
                exact
                component={ChildStep3}
              />
              <PrivateRoute
                path="/registration/child/step_4/:id"
                exact
                component={ChildStep4}
              />
              <PrivateRoute
                path="/registration/child/step_5/:id"
                exact
                component={ChildStep5}
              />
              <PrivateRoute path="/postbox/" exact component={PostBox} />
              <PrivateRoute path="/orders/" exact component={Order} />
              <PrivateRoute
                path="/strategyChange/:id"
                exact
                component={StrategyChange}
              />
              <PrivateRoute path="/setup/" exact component={Setup} />
              <PrivateRoute
                path="/setup/personal-data"
                exact
                component={SetupPersonalData}
              />
              <PrivateRoute path="/setup/tax" exact component={SetupTax} />
              <PrivateRoute
                path="/setup/accounts"
                exact
                component={SetupAccounts}
              />
              <PrivateRoute
                path="/setup/cash_in_out"
                exact
                component={SetupCashInOut}
              />
              <PrivateRoute
                path="/setup/savingsplan/:id"
                exact
                component={Savingsplan}
              />
              <PrivateRoute
                path="/setup/savingsplan/new/:productid"
                exact
                component={Savingsplan}
              />
              <PrivateRoute
                path="/setup/authorisation"
                exact
                component={SetupAuthorisation}
              />
              <PrivateRoute path="/setup/wphg" exact component={SetupWphg} />
              <PrivateRoute
                path="/setup/password"
                exact
                component={SetupPassword}
              />
              <PrivateRoute
                path="/setup/exemption_order"
                exact
                component={SetupExemptionOrder}
              />
              <PrivateRoute
                path="/delete-product/:id"
                exact
                component={DeleteProduct}
              />
            </Switch>
          </Suspense>
        </div>
      </div>
      <Footer />
    </div>
  </Router>
);

export default Routes;
