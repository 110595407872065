export default [
  {
    start: "2020-02-01T11:11:49.127Z",
    end: "2020-03-31T23:59:00.127Z",
    headline: "4. Aktionen:",
    text:
      '<strong>Winter Sparplan geschenkt</strong><br/>Hiermit akzeptiere ich die <a href="https://www.quirion.de/fileadmin/redaktion/PDFs/quirion_Teilnahmebedingungen_Sparplan_Q12020_01022020-31032020.pdf" target="_blank">Teilnahmebedingungen</a>  und bestätige meine Teilnahme an der Aktion.',
    actionId: "SP01022020-31032020",
    accountTypes: [
      "childJoinedAccount",
      "childSingleAccount",
      "singleAccount",
      "joinedAccount"
    ],
    cases: ["createAccount"]
  },
  {
    start: "2020-04-01T11:11:49.127Z",
    end: "2020-06-30T23:59:00.127Z",
    headline: "4. Aktionen:",
    text:
      '<strong>Neukundenaktion „Fit in den Sommer“<br/>30,00 € Sparrate geschenkt</strong><br/>Hiermit akzeptiere ich die <a href="https://www.quirion.de/fileadmin/redaktion/PDFs/quirion_Teilnahmebedingungen_Sparplan_Q22020_01042020-30062020.pdf" target="_blank">Teilnahmebedingungen</a>  und bestätige meine Teilnahme an der Aktion.',
    actionId: "SP01042020-30062020",
    accountTypes: [
      "childJoinedAccount",
      "childSingleAccount",
      "singleAccount",
      "joinedAccount"
    ],
    cases: ["createAccount"]
  },
  {
    start: "2020-05-04T00:00:01.000Z",
    end: "2020-06-15T23:59:59.000Z",
    headline: "Aktionen",
    text:
      '<strong>Treueprämie-Aktion 2020</strong><br/>Hiermit akzeptiere ich die <a href="https://www.quirion.de/fileadmin/redaktion/PDFs/quirion_Teilnahmebedingungen_Aufstockung_05-06.2020.pdf" target="_blank">Teilnahmebedingungen</a> und bestätige meine Teilnahme an der Aktion.',
    actionId: "BK/AUF052020-062020",
    accountTypes: [
      "childJoinedAccount",
      "childSingleAccount",
      "singleAccount",
      "joinedAccount"
    ],
    cases: ["setTopup"]
  }
];
