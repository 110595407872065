/* eslint-disable */
import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { updateUserLastActivity } from "../actions/userActions";
import helper_global from "../utils/helper_global";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAdmin: false
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({ loaded: true, isAuthenticated: true });
        if (user.signInUserSession.accessToken.payload["cognito:groups"]) {
          user.signInUserSession.accessToken.payload["cognito:groups"].forEach(
            function(element) {
              if (element === "Admin") {
                // its a customer-service one
                this.setState({ isAdmin: true });
              }
            }.bind(this)
          );
        }
      })
      .catch(/*console.log('header no auth')*/);
  }

  signOut() {
    //console.log('call signOut')

    Auth.signOut()
      .then(data => {
        location.href = "/logout/";
      })
      .catch(err => {
        console.log("err", err);
        window.localStorage.clear();
        location.href = "/logout/";
      });
  }

  linkTodos() {
    const header = document.getElementById(`header`);
    header.classList.contains(`open`) &&
      header.classList.toggle(`open`) &&
      document.body.classList.toggle(`showMenu`);
    this.props.updateUserLastActivity();
  }

  render() {
    const { user, person } = this.props;
    let navPoints = [];
    //let linkRobo = <NavLink to="/robo" className="header__login-button"><span className="header__login-button-label">Robo</span></NavLink>
    if (user.loaded) {
      let namedOwner = this.props.user.data.attributes.email;
      if (
        person &&
        person.firstName &&
        person.lastName &&
        person.firstName.length > 0 &&
        person.lastName.length > 0
      ) {
        namedOwner = person.firstName + " " + person.lastName;
      }

      let showUnreadedDocs = "";
      //console.log(this.props.unreadedPostBoxElements)
      if (parseInt(this.props.unreadedPostBoxElements) !== 0) {
        showUnreadedDocs = <div>{this.props.unreadedPostBoxElements}</div>;
      }

      // <li className="list"><a href="#">Aufträge</a></li>

      let userIsLegitimated = !!(person.postIdent)

      if (this.state.isAuthenticated) {
        navPoints = (
          <ul>
            <li className="logout" onClick={() => this.signOut()}>
              <a test-selector="signout">Abmelden</a>
            </li>
            {
              userIsLegitimated && (
                <>
                  <li className="message" >
                    <NavLink
                      to="/postbox"
                      onClick={() => this.linkTodos()}
                      className=""
                      test-selector="emails"
                    >
                      Nachrichten{showUnreadedDocs}
                    </NavLink>
                  </li>
                  <li className="user" >
                    <NavLink
                      to="/setup"
                      onClick={() => this.linkTodos()}
                      className=""
                      test-selector="username"
                    >
                      {namedOwner}
                    </NavLink>
                  </li>
                </>
              )
            }
            <li className={"dashboard"}>
              <NavLink
                to="/"
                onClick={() => this.linkTodos()}
                className=""
                test-selector="goToDashboard"
              >
                zum Dashboard
              </NavLink>
            </li>
            <li
              onClick={() => {
                const header = document.getElementById(`header`);
                header.classList.toggle(`open`);
                document.body.classList.toggle(`showMenu`);
              }}
              className="responsive_opener responsive"
            ></li>
            <li
              test-selector="logout"
              className="logout responsive"
              onClick={() => this.signOut()}
            ></li>
          </ul>
        );
      }

      return (
        <header id="header">
          <div className="holder">
            <div id="homeLinkHolder">
              <NavLink to="/" onClick={() => this.linkTodos()} className="">
                <div id="homeLink"></div>
              </NavLink>
            </div>
            {navPoints}
            <ul className="meta_menu">
              <li
                className="link shy"
                onClick={() =>
                  helper_global.externalLink("quirion_datenschutz")
                }
                title={helper_global.externalLink("quirion_datenschutz", true)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Datenschutz
              </li>
              <li
                className="link shy"
                onClick={() => helper_global.externalLink("quirion_impressum")}
                title={helper_global.externalLink("quirion_impressum", true)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Impressum
              </li>
            </ul>
          </div>
        </header>
      );
    } else {
      return <header id="header"></header>;
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    person: state.person,
    unreadedPostBoxElements: state.unreadedPostBoxElements
  };
};

Header.propTypes = {
  updateUserLastActivity: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, { updateUserLastActivity })(Header)
);
