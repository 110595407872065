export const listBusinessPartners = `query ListBusinessPartners(
  $filter: ModelBusinessPartnerFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      number
      accountType
      name
      statusContract
      avaloqSync
      advisor
      branch
      balanceActual
      balanceStart
      totalChangeBegin
      totalChangeYearStart
      twrPerformanceStart
      twrPerformanceYearStart
      mwrPerformanceStart
      mwrPerformanceYearStart
      joinedMember
      childMember
      wphgExpireDate
      wphg
      owner
      members
      bankAccounts {
       items {
          id
          number
          name
          accountHolder
          iban
          bic
          currency
          exchangeRate
          accountType
          balance
          balanceEur
          reference
       }
      }
     products {
       items {
         id
         number
         name
         strategy
         recommendedStrategy
         savingsPlans {
           items {
             id
             rate
             nextDate
           }
         }
         conditions {
           items {
             condition {
               id
               title
             }
           }
         }
         depots {
           items {
             id
             number
             balance
             twr
           }
         }
         liquidAccounts {
           items {
             id
             accountType
             iban
             balance
           }
         }
       }
     }
    }
    nextToken
  }
}
`;

export const getPerson = `query GetPerson($id: ID!) {
  getPerson(id: $id) {
    id
    eMail
    externalId
    avaloqId
    postIdent
    communicationEMail
    communicationEMailVerified
    dataComplete
    needToMigrateDoc
    identityId
    gender
    salutation
    title
    titleAdd
    firstName
    middleName
    lastName
    phoneNumber
    mobilePhoneNumber
    passportNumber
    passportIssuer
    passportIssuerDate
    passportValidToDate
    passportPlaceOfIssue
    passportCountryOfIssuer
    nationality
    street
    streetNumber
    zip
    city
    state
    country
    addressType
    maritalStatus
    taxNumber
    birthday
    birthplace
    nativeCountry
    occupation
    occupationIndustry
    taxOnlyInGermany
    additionalTax
    mmw
    campaign
    postIdentCaseId
    identificationMethod
    identificationStatus
    identificationType
    postbox {
      items {
        id
        title
        date
        documentKey
        readTime
        owner
        members
      }
      nextToken
    }
    consultant {
      id
      branch
      branchStreet
      branchZip
      branchCity
      branchState
      counter
      firstName
      lastName
      email
      phoneNumber
      mobilePhoneNumber
      status
      s3BucketPicLink
      createdAt
      ndl {
        id
        name
        city
        zip
        street
        streetNr
      }
    }    
    businessPartners(sortDirection:ASC,limit:1000) {
      items {
            bpId
            waitForCode
            waitForBirthCertificate
        		businessPartner{
            id
            number
            accountType
            balanceDate
            name
            statusContract
            avaloqSync
            advisor
            branch
            balanceActual
            balanceStart
            totalChangeBegin
            totalChangeYearStart
            twrPerformanceStart
            twrPerformanceYearStart
            mwrPerformanceStart
            mwrPerformanceYearStart
            joinedMember
            childMember
            otherMembers
            portfolioHistory
            portfolioHistoryYear
            performanceStartDate
            wphgExpireDate
            wphg
            orders(limit:1000,filter:{or:[{status:{eq:RUNNING}}]}){
              items{
                productId
                amount
                sfType
                status
                createdAt
                EndOfStateMachine
                subOrder(filter:{or:[{status:{eq:PENDING}}]}){
                  items{
                    amount
                    status
                    type
                    createdAt      
                    transaction{
                      bankAccount{
                        iban
                        accountType
                      }
                    }            
                  }
                }
              }
            }
            businessPartnerCode{
              items{
                attorney
                businessPartnerId
                accountCode
                accountCodeStatus
                acceptedByOwner
              }
            }            
            persons{
              items{
                id
                waitForCode
                role
                waitForBirthCertificate
                person{
                  id
                  firstName
                  lastName
                  postIdent
                  communicationEMail
                  city
                  birthday
                  street
                  streetNumber
                  zip
                }
              }
              nextToken
            }
            bankAccounts {
             items {
                id
                number
                name
                accountHolder
                iban
                bic
                currency
                exchangeRate
                accountType
                balance
                balanceEur
                reference
                transactions {
                  items {
                    id
                    number
                    amount
                    currency
                    valutaDate
                    bookingDate
                    sequenceNumber
                    ktoText
                    mandat
                    mandatSignatureDate
                    auftrag
                    metaTyp
                    auftragsArt
                    ereignis
                    ereignisStatus
                    internalBookingText
                    externalBookingText
                    status
                    owner
                    members
                  }
                  nextToken
                }
             }
            }
            products( sortDirection:ASC, filter:{or:[{statusProduct:{eq:"WAITMONEY"}},{statusProduct:{eq:"INCLOSE"}},{statusProduct:{eq:"PENDING"}},{statusProduct:{eq:"OPEN"}},{statusProduct:{eq:"open"}}]}){
              items {
                id
                number
                name
                category
                strategy
                recommendedStrategy
                recommendedName
                balanceActual
                balanceStart
                balanceDate
                totalChangeBegin
                totalChangeYearStart
                twrPerformanceStart
                twrPerformanceYearStart
                mwrPerformanceStart
                mwrPerformanceYearStart
                statusProduct
                portfolioHistory
                portfolioHistoryYear
                performanceStartDate
                retirementYear
                wphgInitialAmount
                wphgExpireDate
                wphg
                depotBalance
                depotItems
               savingsPlans {
                 items {
                   id
                   rate
                   nextDate
                   endDate
                   counter
                   bankAccount {
                      id
                      number
                      name
                      accountHolder
                      iban
                      bic
                      currency
                      exchangeRate
                      accountType
                      balance
                      balanceEur
                      reference
                      eod
                      eodDate
                      owner
                      members
                  }                   
                 }
               }
               conditions {
                 items {
                   condition {
                     id
                     advisor
                     category
                     strategy
                     accountType
                     minInvest
                     minIncrease
                     minDecrease
                     minRate
                     allowance
                     cashoutplan
                     savingsplan
                     consulting
                     feeQuirion
                     feeAdvisor
                     validFrom
                     validTo
                     homepageUrl
                     conditionLink
                     advisorConfirmText
                     contact
                     phoneNumber
                     description
                     descriptionTitle
                     title
                     name
                     newBp
                     newIps
                     costProfile
                   }
                 }
               }
               depots {
                 items {
                   id
                   balance
                   number
                   twr
                   mwr
                   profit
                   depotItems {
                      items {
                        id
                        number
                        quantity
                        value
                        valueCurrency
                        einstandskurs
                        aktuellerKurs
                        notRealGUV
                        notRealGUVCurrency
                        notRealGUVPercentage
                        referenceDate
                        avaloqAssetId
                      }
                   }
                 }
               }
               liquidAccounts {
                 items {
                   id
                   accountType
                   iban
                   balance
                 }
               }
             }
           }
            owner
            members
          }
      }
    }
    owner
    members
  }
}
`;

export const getPersonPostbox = `query GetPerson($id: ID!,$limit: Int, $nextToken: String, $filter: ModelPostboxFilterInput) {
  getPerson(id: $id) {
    postbox(limit: $limit,nextToken: $nextToken, filter:$filter,  sortDirection:DESC) {
      items {
        id
        title
        date
        documentKey
        readTime
        owner
        members
      }
      nextToken
    }
  }
}
`;

export const getOrdersByPerson = `query GetPerson( $id: ID!, $limit: Int,$nextToken: String, $filter: ModelOrderFilterInput) {
  getPerson(id: $id) {
    orders(  limit: $limit, nextToken: $nextToken,filter: $filter,  sortDirection:DESC) {
      items {
        id
        createdAt
        productId
        businessPartnerId
        personId
        amount
        sfType
        sfARN
        status
        createdAt
        EndOfStateMachine
        subOrder {
          items{
            id
            amount
            status
            createdAt
          }
          nextToken
        }        
        
      }
      nextToken
    }
  }
}  
`;
