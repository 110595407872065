//import axios from 'axios'

export const login = response => {
  return (dispatch, getState) => {
    dispatch({ type: "LOGIN_OK", response });
  };
};

export const addMessage = messageTyp => {
  return (dispatch, getState) => {
    dispatch({ type: "MESSAGE_ADD", messageTyp });
  };
};

export const setUserStatusActiveProduct = activeProduct => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_USER_STATUS", activeProduct });
  };
};

export const updateUserLastActivity = () => {
  return (dispatch, getState) => {
    dispatch({ type: "UPDATE_USER_LAST_ACTIVITY" });
  };
};

export const loadUser = response => {
  return (dispatch, getState) => {
    dispatch({ type: "GETUSER_OK", response });
  };
};

export const loadPerson = response => {
  return (dispatch, getState) => {
    dispatch({ type: "GETPERSON_OK", response });
  };
};

export const loadUnreadedPostBox = response => {
  return (dispatch, getState) => {
    dispatch({ type: "GETUNREADEDPOSTBOX_OK", response });
  };
};

export const loadConditions = response => {
  return (dispatch, getState) => {
    dispatch({ type: "GETCONDITIONS_OK", response });
  };
};

export const logout = () => {
  return {
    type: "LOGOUT"
  };
};

export const showLoader = status => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_LOADER", status });
  };
};

export const storeLastLocation = myLocation => {
  return (dispatch, getState) => {
    dispatch({ type: "STORE_LAST_LOCATION", myLocation });
  };
};
