import React from "react";
import { withRouter } from "react-router-dom";
import helperBank from "./helper_bank.js";
import helper_global from "./helper_global.js";
import moment from "moment";
import "moment/locale/de";
moment.locale(`de`);

const helperProduct = {
  getMyProducts: (depots, types = [], depotNeedSync = true) => {
    let products = [];
    // types can be > [to be defined]
    if (depots !== undefined) {
      depots.forEach(function(depot) {
        depot.products.items.forEach(function(product) {
          if (depotNeedSync) {
            if (depot.avaloqSync === true) {
              products.push(product);
            }
          } else {
            products.push(product);
          }
        });
      });
    }
    return products;
  },
  getBpIdByProduct: (depots, stragyId) => {
    let bpId = null;
    if (depots !== undefined) {
      depots.forEach(function(depot) {
        depot.products.items.forEach(function(product) {
          // eslint-disable-next-line
          if (product.id == stragyId) {
            bpId = depot.id;
          }
        });
      });
    }
    return bpId;
  },
  getBpByBpId: (depots, bpId) => {
    let bp = null;
    if (depots !== undefined) {
      depots.forEach(function(depot) {
        // eslint-disable-next-line
        if (depot.id == bpId) {
          bp = depot;
        }
      });
    }
    return bp;
  },
  getBpByProduct: (depots, stragyId) => {
    let bp = null;
    //console.log('getBpByProduct',depots,stragyId)
    if (depots !== undefined) {
      depots.forEach(function(depot) {
        depot.products.items.forEach(function(product) {
          if (product.id === stragyId) {
            bp = depot;
          }
        });
      });
    }
    //console.log('fopund',bp)
    return bp;
  },
  getProductByProductId: (depots, stragyId) => {
    let product = null;
    if (depots !== undefined) {
      depots.forEach(function(depot) {
        depot.products.items.forEach(function(productElement) {
          if (productElement.id === stragyId) {
            product = productElement;
          }
        });
      });
    }
    return product;
  },
  getArrProductPart: product => {
    let arrParts = [];

    let depotItems = [];
    if (product && product.depotItems) {
      depotItems = Array.from(JSON.parse(product.depotItems));
    }

    if (depotItems) {
      // es wird aktuell nur ein depot unter einem product unterstützt
      for (let i = 0; i < depotItems.length; i++) {
        if (arrParts[depotItems[i].class]) {
          if (arrParts[depotItems[i].class][depotItems[i].category]) {
            arrParts[depotItems[i].class][depotItems[i].category].push(
              depotItems[i].value
            );
          } else {
            arrParts[depotItems[i].class][depotItems[i].category] = [];
            arrParts[depotItems[i].class][depotItems[i].category].push(
              depotItems[i].value
            );
          }
        } else {
          arrParts[depotItems[i].class] = [];
          arrParts[depotItems[i].class][depotItems[i].category] = [];
          arrParts[depotItems[i].class][depotItems[i].category].push(
            depotItems[i].value
          );
        }
      }
    }

    if (product.liquidAccounts && product.liquidAccounts.items) {
      let liquidValue = [];
      for (let i = 0; i < product.liquidAccounts.items.length; i++) {
        liquidValue.push(product.liquidAccounts.items[i].balance);
      }
      arrParts["Liquiditaet"] = liquidValue;
    }
    return arrParts;
  },
  getChartOptionAllokation: assetsData => {
    if (!assetsData) return {};

    let assets = assetsData;
    let pieRing    = [];
    let legendData = [];

    let labelStyle = {
      normal: {
        position: "center",
        fontSize: 15,
        color: "#a091a5",
        fontWeight: "normal"
      }
    }

    Object.values(assets).map((assetType, idx) => {
      if (!assetType.id) return

      const { color, percentage, label } = assetType;

      let itemLabel = helperBank.formatMoney(percentage, 2) + " % " + label;
      let pieAssetType = {
        value: percentage,
        name: itemLabel,
        itemStyle: {
          color: color,
        },
        label: labelStyle,
      }

      if (percentage > 0) {
        pieRing.push(pieAssetType);
        legendData.push(itemLabel);
      }
    });

    let chartOptions = {
      tooltip: {
        trigger: "item",
        formatter: function(params, ticket, callback) {
          let arrName = params.name.split(" ");
          return arrName[2] + "<br/>" + arrName[0] + " %";
        },
        backgroundColor: "#00a1f5",
        extraCssText: "text-align:center"
      },
      legend: {
        orient: "vertical",
        x: "center",
        y: "center",
        data: legendData,
      },
      series: [
        {
          name: "Allokation",
          type: "pie",
          radius: ["75%", "90%"],
          avoidLabelOverlap: true,
          label: {
            normal: {
              show: false,
              position: "center"
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold"
              }
            }
          },
          labelLine: {
            normal: {
              show: true
            }
          },
          data: pieRing,
        }
      ],
    }
    return chartOptions;
  },


  getDashboardDataForProduct: product => {
    if (!product)  return;

    let depotItems = product.depotItems;
    depotItems = depotItems ? JSON.parse(depotItems) : {};

    let assets = {
      stocks: {
        id: "stocks",
        label: "Aktien",
        color: "#4e3963",
        colorMuted: "#2a1340",
        className: "aktien",
        items: helperProduct.getPartsByClass(depotItems, "Aktien"),
        percentage: 0,
        value: 0,
        notRealGUV: 0,
      },
      bonds: {
        id: "bonds",
        label: "Anleihen",
        color: "#b75edb",
        colorMuted: "#964db3",
        className: "anleihen",
        items: helperProduct.getPartsByClass(depotItems, "Anleihen"),
        percentage: 0,
        value: 0,
        notRealGUV: 0,
      },
      commodities: {
        id: "commodities",
        label: "Rohstoffe",
        color: "#ff9100",
        colorMuted: "#c56200",
        className: "rohstoffe",
        items: helperProduct.getPartsByClass(depotItems, "Rohstoffe"),
        percentage: 0,
        value: 0,
        notRealGUV: 0,
      },
      liquidity: {
        id: "liquidity",
        label: "Liquidität",
        color: "#56cb94",
        colorMuted: "#56cb94",
        className: "liquiditaet",
        items: [...product.liquidAccounts.items],
        percentage: 0,
        value: 0,
        notRealGUV: 0,
      },
    }

    let assetTypes = Object.keys(assets);

    // STEP 1: calculate totals
    assets.value = 0;
    assets.notRealGUV = 0;

    assetTypes.map( key => {
      let items = assets[key].items;
      let value = 0;
      let notRealGUV = 0;

      if (items && items.length) {
        items.map((item) => {
          if (key === "liquidity") {
            value += item.balance;
          } else {
            value += item.value;
            notRealGUV += item.notRealGUV;
          }
        })
      }

      // asset type
      assets[key].value = value;
      assets[key].notRealGUV = notRealGUV;

      // total
      assets.value += value;
      assets.notRealGUV += notRealGUV;
    });

    // STEP 2: calculate percentages
    assetTypes.map( key => {
      let items = assets[key].items;
      if (items && items.length) {
        items.map((item, idx) => {
          if (key === "liquidity") {
            // add value to item for identic behaviour
            items[idx].value =  item.balance || 0;
          }
          items[idx].percentage = (items[idx].value*100) / (assets.value || 1);
        })
      }
      assets[key].percentage = (assets[key].value * 100) / (assets.value || 1);
    });

    // STEP 3: add default percentages form strategy if no items are present
    if (!product.depotItems) {
      let strategy = product.strategy;
      if (strategy.indexOf("spardepot") >= 0) {
        // eg: strategy id is `spardepot30`
        strategy = strategy.split("spardepot");
        assets.stocks.percentage = parseFloat(strategy[1]); 
        assets.bonds.percentage = parseFloat(100 - assets.stocks.percentage);
      } else if (strategy.indexOf(":") >= 0) {
        // eg: strategy id is `70:30`
        strategy = strategy.split(":");
        assets.stocks.percentage = parseFloat(strategy[0]); 
        assets.bonds.percentage = parseFloat(strategy[1]);
      }
    }

    // STEP 4: add categories
    assetTypes.map( key => {
      let items = assets[key].items;
      let categories = {}

      if (items && items.length) {
        items.map( item => {
          let category = categories[item.category] || { 
            label: item.category,
            items: [],
            value: 0,
          }

          if (key === "liquidity") {
            category.label = "Liquidität";
            item.name = "Liquidität";
            item.long_name = "Liquidität";
          }

          category.value += item.value;
          category.items.push(item);
          categories[item.category] = category;
        })

        categories = Object.values(categories);
        categories.map((category, idx) => {
          categories[idx].percentage = (category.value * 100) / assets.value
        });
      } else {
        categories = [];
      }

      assets[key].categories = categories;
    });

    return assets
  },
  getChartOptionAllokationDetailed: assetsData => {
    if (!assetsData) return {};

    let assets = assetsData;
    let pieRingInner  = [];
    let pieRingMiddle = [];
    let pieRingOuter  = [];
    let itemStyle = {
      borderWidth: 1,
      borderRadius: 4
    }
    let labelStyle = {
      normal: {
        position: "center",
        fontSize: 16,
        color: "#a091a5",
        fontWeight: "normal"
      }
    }

    Object.values(assets).map((assetType, idx) => {
      if (!assetType.id) return

      const { categories, color, colorMuted, percentage, label } = assetType;

      // inner ring
      let pieAssetType = {
        value: percentage,
        name: label,
        itemStyle: {
          color: colorMuted,
        },
        label: labelStyle,
      }
      pieRingInner.push(pieAssetType);

      if (categories) {
        // middle & outer ring
        categories.sort( (a, b) => (b.value - a.value) ).map(({
          label,
          percentage,
          items,
        }) => {

          // middle ring
          let itemStyle = {
            ...itemStyle,
            color,
            borderColor: colorMuted,
          }
          let pieCategory = {
            value: percentage,
            name: label,
            itemStyle,
            label: labelStyle,
          }
          pieRingMiddle.push(pieCategory);

          // outer ring
          items.sort( (a, b) => (b.value - a.value) ).map(({
            percentage,
            shortName, 
            name, 
            isin
          }) => {
            let pieItem = {
              value: percentage,
              name: shortName || name,
              long_name: name,
              isin: isin || "",
              itemStyle,
            }
            pieRingOuter.push(pieItem);
          })
        })
      }
    });

    let labelOptions = {
      avoidLabelOverlap: true,
      label: {
        normal: {
          show: false,
          position: "center"
        },
        emphasis: {
          show: false,
          textStyle: {
            fontSize: "30",
            fontWeight: "bold"
          }
        }
      },
      labelLine: {
        normal: {
          show: true
        }
      },
    };

    let chartSeries;

    // either length needs to be > 1 because liquidity is always present
    if (pieRingMiddle.length > 1 && pieRingOuter.length > 1) {
      chartSeries = [
        // inner ring
        {
          name: "Allokation",
          type: "pie",
          radius: ["0", "30%"],
          ...labelOptions,
          data: pieRingInner,
        },

        // middle ring
        {
          name: "Allokation detailed",
          type: "pie",
          radius: ["40", "55%"],
          ...labelOptions,
          data: pieRingMiddle,
        },
        
        // outer ring
        {
          name: "Allokation very detailed",
          type: "pie",
          radius: ["55%", "70%"],
          data: pieRingOuter,
        }
      ]
    } else {
      chartSeries = [
        // show inner data on full size, if there are no depotItems
        {
          name: "Allokation",
          type: "pie",
          radius: ["0%", "70%"],
          ...labelOptions,
          data: pieRingInner,
        }
      ]
    }

    let chartOptions = {
      series: chartSeries,
      tooltip: {
        trigger: "item",
        formatter: function(params) {
          let text = params.seriesName === "Allokation very detailed"
                     ? params.data.long_name
                     : params.name;
          text += "<br/>" + helperBank.formatMoney(params.data.value, 2) + " %";
          return text
        },
        backgroundColor: "#00a1f5",
        extraCssText: "text-align:center"
      },
    }
    return chartOptions;
  },

  getMonth: arg => {
    var month = [];
    month[1] = "Januar";
    month[2] = "Februar";
    month[3] = "März";
    month[4] = "April";
    month[5] = "Mai";
    month[6] = "Juni";
    month[7] = "Juli";
    month[8] = "August";
    month[9] = "September";
    month[10] = "Oktober";
    month[11] = "November";
    month[12] = "Dezember";
    return month[arg];
  },
  getChartOptionValueReal: product => {
    var date = [];
    var data = [];

    if (product.portfolioHistory) {
      let portfolioHistory = JSON.parse(product.portfolioHistory);
      data = portfolioHistory.length
        ? portfolioHistory.map(element => {
            return element.v;
          })
        : "";

      date = portfolioHistory.length
        ? portfolioHistory.map(element => {
            let arrDate = element.d.split("-");
            return (
              parseInt(arrDate[2]) +
              ". " +
              helperProduct.getMonth(parseInt(arrDate[1])) +
              " " +
              arrDate[0]
            );
          })
        : "";
    }

    let leftSpace = 0;
    let maxXValue = Math.max(...data);

    if (maxXValue < 500) {
      leftSpace = 40;
    } else if (maxXValue >= 4000000000) {
      leftSpace = 110;
    } else if (maxXValue >= 4000000) {
      leftSpace = 100;
    } else if (maxXValue >= 400000) {
      leftSpace = 80;
    } else if (maxXValue >= 40000) {
      leftSpace = 70;
    } else if (maxXValue >= 500) {
      leftSpace = 60;
    }

    let option = {
      tooltip: {
        trigger: "axis",
        formatter: function(params, ticket, callback) {
          return (
            params[0].axisValue +
            "<br/>" +
            helperBank.formatMoney(params[0].value, 2) +
            " €"
          );
        },
        backgroundColor: "#00a1f5",
        extraCssText: "text-align:center"
      },
      toolbox: {
        feature: {
          /*dataZoom: {
                        yAxisIndex: 'none'
                    },*/
          restore: {
            title: " "
          }
          //,saveAsImage: {}
        }
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: date
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        axisLabel: {
          formatter: function(value) {
            return helperBank.formatMoney(value, 0) + " €";
          }
        }
      },
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 10,
          handleIcon:
            "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
          handleSize: "80%",
          handleStyle: {
            color: "#fff",
            shadowBlur: 3,
            shadowColor: "rgba(0, 0, 0, 0.6)",
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }
      ],
      series: [
        {
          name: "Entwicklung in EUR",
          type: "line",
          smooth: true,
          symbol: "none",
          sampling: "average",
          itemStyle: {
            color: "#564467"
          },
          areaStyle: {
            type: "default",
            color: "#00a1f5",
            opacity: 0.2
          },

          data: data
        }
      ],
      grid: {
        left: leftSpace,
        top: 40,
        right: 20,
        bottom: 80
      }
    };
    return option;
  },
  toogleOpenSavinsplan: (value, dieses) => {
    if (dieses.state.openSavinsplan === value) {
      dieses.setState({ openSavinsplan: "" });
    } else {
      dieses.setState({ openSavinsplan: value });
    }
  },
  getSparplanList: (product, dieses) => {
    //console.log(dieses)
    let title = "Sparpläne in der Übersicht";
    let extraMargin = "list";
    let list = [];

    let hideCashoutButtons = "";
    //let hideSavingsplansButtons = ''
    if (product.savingsPlans && product.savingsPlans.items.length > 0) {
      hideCashoutButtons = " dontDisplayContent";
      title = "Sparpläne in der Übersicht";
      list.push(
        <thead key="head">
          <tr>
            <td>Intervall</td>
            <td>Sparrate</td>
            <td>nächste Ausführung</td>
            <td></td>
          </tr>
        </thead>
      );

      let tbody = [];
      for (let i = 0; i < product.savingsPlans.items.length; i++) {
        let intervall = product.savingsPlans.items[i].counter + " monatlich";
        if (product.savingsPlans.items[i].counter === 1) {
          intervall = "monatlich";
        } else if (product.savingsPlans.items[i].counter === 3) {
          intervall = "quartalsweise";
        }

        let classShowOrHide = "";

        if (product.savingsPlans.items[i].id === dieses.state.openSavinsplan) {
          classShowOrHide = " show";
        }

        var nextDate = new Date(product.savingsPlans.items[i].nextDate);

        let tempElement = (
          <tr
            key={product.savingsPlans.items[i].id}
            className={"row " + classShowOrHide}
          >
            <td>{intervall}</td>
            <td>
              {helperBank.formatMoney(product.savingsPlans.items[i].rate, 2)} €
            </td>
            <td>{moment(nextDate).format(`DD.MM.YYYY`)}</td>
            <td
              onClick={() => {
                helperProduct.toogleOpenSavinsplan(
                  product.savingsPlans.items[i].id,
                  dieses
                );
              }}
            >
              Anzeigen
            </td>
          </tr>
        );
        tbody.push(tempElement);

        if (product.savingsPlans.items[i].id === dieses.state.openSavinsplan) {
          classShowOrHide = " show";
          let lastExecution = "unbefristet";
          if (product.savingsPlans.items[i].endDate) {
            lastExecution = moment(
              product.savingsPlans.items[i].endDate
            ).format(`DD.MM.YYYY`);
          }
          let detailsSavingsplan = (
            <tr
              key={"details_" + product.savingsPlans.items[i].id}
              className="details"
            >
              <td colSpan="4">
                <div className="details table">
                  <div>
                    <div>Zahlweise</div>
                    <div>{intervall}</div>
                    <div></div>
                  </div>
                  <div>
                    <div>Nächste Ausführung</div>
                    <div>{moment(nextDate).format(`DD.MM.YYYY`)}</div>
                    <div></div>
                  </div>
                  <div>
                    <div>Letzte Ausführung</div>
                    <div>{lastExecution}</div>
                    <div>
                      <span
                        onClick={() => {
                          dieses.props.history.push(
                            "/setup/savingsplan/" +
                              product.savingsPlans.items[i].id
                          );
                        }}
                      >
                        Ändern
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
          tbody.push(detailsSavingsplan);
        }
      }
      list.push(<tbody key="body">{tbody}</tbody>);
    } else if (product.cashoutplans && product.cashoutplans.length > 0) {
      title = "Auszahlungspläne in der Übersicht";
    } else {
      // kein savinsplan und kein Auszahlunsplan
      title = "Jetzt Sparplan einrichten";
      extraMargin = "noElements";
    }
    //console.log(product,product.savingsplans)
    hideCashoutButtons = " dontDisplayContent"; // vorerst immer ausblenden
    return (
      <div className="cashInOutPlans">
        <div className="title">{title}</div>
        <table className="table list_elements">{list}</table>
        <div>
          <div className={"button_holder " + extraMargin}>
            <span
              className="button important"
              onClick={() => {
                dieses.props.history.push(
                  "/setup/savingsplan/new/" + product.id
                );
              }}
            >
              Neuer Sparplan
            </span>
            <span
              className="button"
              onClick={() => dieses.props.history.push("/setup/cash_in_out")}
            >
              Alle Sparpläne anzeigen
            </span>
          </div>
          <div className={hideCashoutButtons + " button_holder"}>
            <span className="button important">Neuer Auszahlungsplan</span>
            <span
              className="button"
              onClick={() => dieses.props.history.push("/setup/cash_in_out")}
            >
              Alle Auszahlungspläne anzeigen
            </span>
          </div>
        </div>
      </div>
    );
  },
  convertAWSDate(date) {
    if (date) {
      let arrDate = date.split("-");
      arrDate.map(element => {
        return parseInt(element);
      });
      return arrDate.reverse().join(".");
    }
  },
  getDate(date, formatType = null) {
    let myDate = new Date(date);
    if (formatType === "withTime") {
      return (
        myDate.getDate() +
        "." +
        (myDate.getMonth() + 1) +
        "." +
        myDate.getFullYear() +
        " " +
        myDate.getHours() +
        ":" +
        myDate.getMinutes()
      );
    } else {
      return (
        myDate.getDate() +
        "." +
        (myDate.getMonth() + 1) +
        "." +
        myDate.getFullYear()
      );
    }
  },
  getChartOptionBP: (depot, showPerformanceOverviewSinceStart) => {
    let values = [];
    let valuesDraw = [];
    if (showPerformanceOverviewSinceStart) {
      // seit start
      values = JSON.parse(depot.portfolioHistory);
    } else {
      // seit anfang des jahres
      values = JSON.parse(depot.portfolioHistoryYear);
    }

    if (values) {
      valuesDraw = values.length
        ? values.map(element => {
            return parseInt(element.v);
          })
        : "";
    }

    let minValue = Math.min(...valuesDraw);

    valuesDraw = valuesDraw.length
      ? valuesDraw.map(value => {
          return value - minValue;
        })
      : "";

    let maxDistance = Math.max(...valuesDraw) - Math.min(...valuesDraw);

    let option = {
      axisBottom: {},
      xAxis: {
        data: valuesDraw,
        show: false,
        boundaryGap: false
      },
      yAxis: {
        interval: maxDistance / 4,
        gridLines: {
          drawBorder: false
        },
        splitLine: {
          lineStyle: {
            color: "grey"
          }
        }
      },
      series: [
        {
          data: valuesDraw,
          type: "line",
          color: "white",
          lineStyle: {
            width: 4
          },
          itemStyle: {
            opacity: 0
          }
        }
      ],
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      }
    };
    return option;
  },
  getPartsByClass: (parts, className) => {
    let arrReturn = [];
    for (let i = 0; i < Object.keys(parts).length; i++) {
      if (Object.values(parts)[i].class === className) {
        arrReturn.push(Object.values(parts)[i]);
      }
    }
    return arrReturn;
  },

  // TODO: this should be refactored to separate React components
  getProductPart: assetsData => {
    let markup = [];

    if (assetsData) {
      let assets = assetsData;
      
      // STEP 1: build markup of assets, except liquidity
      Object.values(assets).map((assetType, idx) => {
        if (!assetType.id || (assetType.id === "liquidity")) return
        if (!assetType.items.length) return

        const {label, className, items, value, notRealGUV} = assetType;

        // add title
        markup.push(
          <div key={`${className}-group`} className={`group ${className}`}>
            <span>{label}</span>
          </div>
        );

        // add table head, but on first asset type only
        if (idx === 0) {
          markup.push(
            <div key={`${className}-head`} className="head">
              <div>Kategorie</div>
              <div>Fonds</div>
              <div>Anteil</div>
              <div>Stück</div>
              <div className="icon_info tooltip dark">
                letzter Kurs
                <span className="tooltip-text">Schlusskurs vom Vortag</span>
              </div>
              <div>aktueller Wert in €</div>
              <div>nicht real. GuV in €</div>
              <div>nicht real. GuV in %</div>
            </div>
          )
        }

        // add table row for each item
        items.sort( (a, b) => (b.value - a.value) ).map(({
          value, 
          notRealGUV, 
          isin, 
          category, 
          shortName, 
          percentage,
          quantity, 
          aktuellerKurs,
          notRealGUVPercentage,
        }) => {
          markup.push(
            <div key={isin} className="element">
              <div>{category}</div>
              <div>{shortName}<br/><span className="smaller">{isin}</span></div>
              <div>{helperBank.formatMoney(percentage, 2)} %</div>
              <div>{helperBank.formatMoney(quantity, 3)}</div>
              <div>{helperBank.formatMoney(aktuellerKurs, 2)} €</div>
              <div>{helperBank.formatMoney(value, 2)} €</div>
              <div>{helperBank.formatMoney(notRealGUV, 2)} €</div>
              <div>{helperBank.formatMoney(notRealGUVPercentage, 2)} %</div>
            </div>
          )
        })

        // add table footer
        markup.push(
          <div key={`${className}-finish`} className={`finish ${className}`}>
            <div>{label} Gesamt</div>
            <div/>
            <div/>
            <div/>
            <div/>
            <div>{helperBank.formatMoney(value, 2)} €</div>
            <div>{helperBank.formatMoney(notRealGUV, 2)} €</div>
            <div/>
          </div>
        );
      })

      // STEP 2: build markup of liquidity separately
      markup.push(
        <div key={"liquidity-group"} className="group liquiditaet">
          <span>Liquidität</span>
        </div>
      );
      markup.push(
        <div key={"liquidity-finish"} className="finish liquiditaet">
          <div>Kontostand (Saldo)</div>
          <div/>
          <div>{helperBank.formatMoney(assets.liquidity.percentage, 2)} %</div>
          <div/>
          <div/>
          <div>{helperBank.formatMoney(assets.liquidity.value, 2)} €</div>
          <div/>
          <div/>
        </div>
      );

      // STEP 3: build markup of total value row
      markup.push(
        <div key={"allin"} className="allin">
          <div>Gesamt</div>
          <div/>
          <div/>
          <div/>
          <div/>
          <div>{helperBank.formatMoney(assets.value, 2)} €</div>
          <div>{helperBank.formatMoney(assets.notRealGUV, 2)} €</div>
          <div/>
        </div>
      );

      return markup
    } else {
      return "Loading";
    }
  },
  getBpPersons: depot => {
    let personsen = [];
    for (let i = 0; i < depot.persons.items.length; i++) {
      let tempPersonReturnValue = {};
      let tmpPerson = depot.persons.items[i];
      tempPersonReturnValue.id = tmpPerson.person.id;
      tempPersonReturnValue.firstName = tmpPerson.person.firstName;
      tempPersonReturnValue.lastName = tmpPerson.person.lastName;
      tempPersonReturnValue.postIdent = tmpPerson.person.postIdent;
      tempPersonReturnValue.city = tmpPerson.person.city;
      tempPersonReturnValue.birthday = tmpPerson.person.birthday;
      tempPersonReturnValue.street = tmpPerson.person.street;
      // QUIK-2622: streetNumber removed
      tempPersonReturnValue.zip = tmpPerson.person.zip;
      tempPersonReturnValue.AddedWaitforCode = tmpPerson.waitForCode;
      tempPersonReturnValue.AddedWaitforBirthCertificate =
        tmpPerson.waitForBirthCertificate;
      tempPersonReturnValue.role = tmpPerson.role;
      personsen.push(tempPersonReturnValue);
      //console.log('tmpPerson',tempPersonReturnValue)
    }
    return personsen;
  },
  getChildByDepot: depot => {
    if (
      depot.accountType === "childJoinedAccount" ||
      depot.accountType === "childSingleAccount"
    ) {
      let personsOnDepot = helperProduct.getBpPersons(depot);
      for (let i = 0; i < personsOnDepot.length; i++) {
        if (personsOnDepot[i].role === "owner") {
          return personsOnDepot[i];
        }
      }
    }
  },
  getOtherPersonsByDepot: (depot, presonForExlude) => {
    if (
      depot.accountType === "childJoinedAccount" ||
      depot.accountType === "childSingleAccount"
    ) {
      // für kinderkonten werden nur die legalguardians zurückgegeben
      let allPersonsOnDepot = helperProduct.getBpPersons(depot);
      let personsOnDepot = [];
      for (let i = 0; i < allPersonsOnDepot.length; i++) {
        if (allPersonsOnDepot[i].role === "owner") {
          //personsOnDepot.splice(i, 1);
        } else if (presonForExlude.id === allPersonsOnDepot[i].id) {
          //personsOnDepot.splice(i, 1);
        } else {
          personsOnDepot.push(allPersonsOnDepot[i]);
        }
      }
      return personsOnDepot;
    } else {
      let personsOnDepot = helperProduct.getBpPersons(depot);
      for (let i = 0; i < personsOnDepot.length; i++) {
        if (presonForExlude.id === personsOnDepot[i].id) {
          personsOnDepot.splice(i, 1);
        }
      }
      return personsOnDepot;
    }
  },
  getWaitForCodeStatusByBpAndPerson: (depot, person) => {
    let personsOnDepot = helperProduct.getBpPersons(depot);
    //console.log('personsOnDepot',personsOnDepot)
    let returnValue = null;
    for (let i = 0; i < personsOnDepot.length; i++) {
      if (person.id === personsOnDepot[i].id) {
        returnValue = personsOnDepot[i].AddedWaitforCode;
      }
    }
    return returnValue;
  },
  getBpPersonNames: (depot, personLeading) => {
    let personsOnDepot = helperProduct.getBpPersons(depot);
    let name = "";

    if (
      depot.accountType === "childJoinedAccount" ||
      depot.accountType === "childSingleAccount"
    ) {
      // personLeading = owner = kind
      for (let i = 0; i < personsOnDepot.length; i++) {
        if (personsOnDepot[i].role.toLowerCase() === "owner") {
          name +=
            helperProduct.capitalize(personsOnDepot[i].firstName) +
            "  " +
            helperProduct.capitalize(personsOnDepot[i].lastName);
          personsOnDepot.splice(i, 1);
        }
      }
    } else {
      for (let i = 0; i < personsOnDepot.length; i++) {
        if (personLeading.id === personsOnDepot[i].id) {
          name +=
            helperProduct.capitalize(personsOnDepot[i].firstName) +
            "  " +
            helperProduct.capitalize(personsOnDepot[i].lastName);
          personsOnDepot.splice(i, 1);
        }
      }
      for (let i = 0; i < personsOnDepot.length; i++) {
        name +=
          " und " +
          helperProduct.capitalize(personsOnDepot[i].firstName) +
          "  " +
          helperProduct.capitalize(personsOnDepot[i].lastName);
      }
    }

    return name;
  },
  capitalize(arg) {
    if (typeof arg !== "string") return "";
    return arg.charAt(0).toUpperCase() + arg.slice(1);
  },
  getSavinsPlansByDepots(depots, person, syncNeeded = false) {
    let arrReturn = [];
    depots.forEach(function(depot) {
      if (depot && depot.products && depot.products.items.length > 0) {
        depot.products.items.forEach(function(product) {
          product.savingsPlans.items.forEach(function(savingsPlan) {
            if (depot.accountType === "joinedAccount") {
              let otherPerson = helperProduct.getOtherPersonsByDepot(
                depot,
                person
              );
              savingsPlan.owner =
                helperProduct.capitalize(person.firstName) +
                " " +
                helperProduct.capitalize(person.lastName) +
                " " +
                helperProduct.capitalize(otherPerson.firstName) +
                " " +
                helperProduct.capitalize(otherPerson.lastName);
            } else {
              savingsPlan.owner =
                helperProduct.capitalize(person.firstName) +
                " " +
                helperProduct.capitalize(person.lastName);
            }
            savingsPlan.productName = product.name;
            savingsPlan.strategyId = product.id;
            if (syncNeeded) {
              if (depot.avaloqSync) {
                arrReturn.push(savingsPlan);
              }
            } else {
              arrReturn.push(savingsPlan);
            }
          });
        });
      }
    });
    return arrReturn;
  },
  getProductTitleByConditionAndStrategy(
    condition,
    strategy,
    years_till_no_income = 0
  ) {
    if (condition && condition.id && strategy) {
      let arrConditionId = condition.id.split("_");
      let productName = "";
      if (arrConditionId[1] === "AV.AV") {
        productName =
          condition.title +
          " " +
          helper_global.getRentenalter(years_till_no_income) +
          ", " +
          strategy;
      } else {
        // general investment
        let arrAssets = strategy.split(":");
        productName =
          condition.title +
          " " +
          arrAssets[0] +
          " % Aktien | " +
          (100 - arrAssets[0]) +
          " % Renten";
      }
      return productName;
    } else {
      return "";
    }
  },
  getPreparedWphgData(orgRoboData, version) {
    let userData = Object.assign({}, orgRoboData.userData);
    for (var prop in userData) {
      if (userData[prop] === null) {
        delete userData[prop];
      }
    }

    if (
      userData.roboValues &&
      userData.roboValues.answers &&
      userData.roboValues.version
    ) {
      // wenn das Datum bereits gesezt ist, dann wird davon ausgegangen das es sich um addProduct mit gültigen wphg daten handelt
      // diese bleiben untouched
    } else {
      let roboquestionValue = {
        answers: orgRoboData.previousAnswer,
        version: version
      };
      userData.roboValues = roboquestionValue;
    }
    //console.log('getPreparedWphgData',orgRoboData,userData)
    return userData;
  }
};

export default withRouter(helperProduct);
