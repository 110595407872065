export default [
  { "7": "Abchasien" },
  { "93": "Afghanistan" },
  { "20": "Ägypten" },
  { "355": "Albanien" },
  { "213": "Algerien" },
  { "1": "Amerikanisch-Samoa" },
  { "376": "Andorra" },
  { "244": "Angola" },
  { "1": "Anguilla" },
  { "1": "Antigua und Barbuda" },
  { "240": "Äquatorialguinea" },
  { "54": "Argentinien" },
  { "374": "Armenien" },
  { "297": "Aruba" },
  { "247": "Sankt Helena Ascension und Tristan da Cunha Ascension" },
  { "994": "Aserbaidschan" },
  { "251": "Äthiopien" },
  { "61": "Australien" },
  { "1": "Bahamas" },
  { "973": "Bahrain" },
  { "880": "Bangladesch" },
  { "1": "Barbados" },
  { "375": "Weißrussland Belarus" },
  { "32": "Belgien" },
  { "501": "Belize" },
  { "229": "Benin" },
  { "1": "Bermuda" },
  { "975": "Bhutan" },
  { "591": "Bolivien" },
  { "599": "Bonaire" },
  { "387": "Bosnien und Herzegowina" },
  { "267": "Botswana" },
  { "55": "Brasilien" },
  { "673": "Brunei" },
  { "359": "Bulgarien" },
  { "226": "Burkina Faso" },
  { "257": "Burundi" },
  { "56": "Chile" },
  { "86": "Volksrepublik China" },
  { "682": "Cookinseln" },
  { "506": "Costa Rica" },
  { "599": "Curaçao" },
  { "45": "Dänemark" },
  { "49": "Deutschland" },
  { "1": "Dominica" },
  { "1": "Dominikanische Republik" },
  { "253": "Dschibuti" },
  { "593": "Ecuador" },
  { "503": "El Salvador" },
  { "225": "Elfenbeinküste" },
  { "291": "Eritrea" },
  { "372": "Estland" },
  { "500": "Falklandinseln" },
  { "298": "Färöer" },
  { "679": "Fidschi" },
  { "358": "Finnland" },
  { "33": "Frankreich (ohne Überseegebiete)" },
  { "594": "Französisch-Guayana" },
  { "689": "Französisch-Polynesien" },
  { "241": "Gabun" },
  { "220": "Gambia" },
  { "995": "Georgien" },
  { "233": "Ghana" },
  { "350": "Gibraltar" },
  { "1": "Grenada" },
  { "30": "Griechenland" },
  { "299": "Grönland" },
  { "590": "Guadeloupe" },
  { "1": "Guam" },
  { "502": "Guatemala" },
  { "224": "Guinea" },
  { "245": "Guinea-Bissau" },
  { "592": "Guyana" },
  { "509": "Haiti" },
  { "1": "Hawaii" },
  { "504": "Honduras" },
  { "852": "Hongkong" },
  { "91": "Indien" },
  { "62": "Indonesien" },
  { "964": "Irak" },
  { "98": "Iran" },
  { "353": "Irland" },
  { "354": "Island" },
  { "972": "Israel" },
  { "39": "Italien" },
  { "1": "Jamaika" },
  { "81": "Japan" },
  { "967": "Jemen" },
  { "962": "Jordanien" },
  { "1": "Kaimaninseln" },
  { "855": "Kambodscha" },
  { "237": "Kamerun" },
  { "1": "Kanada" },
  { "238": "Kap Verde" },
  { "7": "Kasachstan" },
  { "974": "Katar" },
  { "254": "Kenia" },
  { "996": "Kirgisistan" },
  { "686": "Kiribati" },
  { "61": "Kokosinseln" },
  { "57": "Kolumbien" },
  { "269": "Komoren" },
  { "242": "Republik Kongo" },
  { "243": "Demokratische Republik Kongo" },
  { "383": "Kosovo" },
  { "385": "Kroatien" },
  { "53": "Kuba" },
  { "965": "Kuwait" },
  { "856": "Laos" },
  { "266": "Lesotho" },
  { "371": "Lettland" },
  { "961": "Libanon" },
  { "231": "Liberia" },
  { "218": "Libyen" },
  { "423": "Liechtenstein" },
  { "370": "Litauen" },
  { "352": "Luxemburg" },
  { "853": "Macau" },
  { "261": "Madagaskar" },
  { "265": "Malawi" },
  { "60": "Malaysia" },
  { "960": "Malediven" },
  { "223": "Mali" },
  { "356": "Malta" },
  { "212": "Marokko" },
  { "692": "Marshallinseln" },
  { "596": "Martinique" },
  { "222": "Mauretanien" },
  { "230": "Mauritius" },
  { "262": "Mayotte" },
  { "389": "Nordmazedonien" },
  { "52": "Mexiko" },
  { "691": "Mikronesien" },
  { "373": "Republik Moldau" },
  { "377": "Monaco" },
  { "976": "Mongolei" },
  { "382": "Montenegro" },
  { "1": "Montserrat" },
  { "258": "Mosambik" },
  { "95": "Myanmar" },
  { "264": "Namibia" },
  { "674": "Nauru" },
  { "977": "Nepal" },
  { "687": "Neukaledonien" },
  { "64": "Neuseeland" },
  { "505": "Nicaragua" },
  { "31": "Niederlande" },
  { "227": "Niger" },
  { "234": "Nigeria" },
  { "683": "Niue" },
  { "850": "Nordkorea" },
  { "6723": "Norfolkinsel" },
  { "47": "Norwegen" },
  { "968": "Oman" },
  { "43": "Österreich" },
  { "670": "Osttimor" },
  { "92": "Pakistan" },
  { "970": "Palästinensische Autonomiegebiete" },
  { "680": "Palau Palau" },
  { "507": "Panama" },
  { "675": "Papua-Neuguinea" },
  { "595": "Paraguay" },
  { "51": "Peru" },
  { "63": "Philippinen" },
  { "649": "Pitcairninseln" },
  { "48": "Polen" },
  { "351": "Portugal" },
  { "1": "Puerto Rico" },
  { "262": "Réunion" },
  { "250": "Ruanda" },
  { "40": "Rumänien" },
  { "7": "Russland" },
  { "599": "Saba" },
  { "508": "Sankt Pierre und Miquelon" },
  { "677": "Salomonen" },
  { "260": "Sambia" },
  { "685": "Samoa" },
  { "378": "San Marino" },
  { "239": "São Tomé und Príncipe São" },
  { "966": "Saudi-Arabien" },
  { "46": "Schweden" },
  { "41": "Schweiz" },
  { "221": "Senegal" },
  { "381": "Serbien" },
  { "248": "Seychellen" },
  { "232": "Sierra Leone" },
  { "263": "Simbabwe" },
  { "65": "Singapur" },
  { "599": "Sint Eustatius" },
  { "1": "Sint Maarten" },
  { "421": "Slowakei" },
  { "386": "Slowenien" },
  { "252": "Somalia" },
  { "34": "Spanien" },
  { "94": "Sri Lanka" },
  { "290": "Sankt Helena Ascension und Tristan da Cunha" },
  { "1": "St. Kitts und Nevis" },
  { "1": "St. Lucia" },
  { "1": "St. Vincent und die Grenadinen" },
  { "27": "Südafrika" },
  { "249": "Sudan" },
  { "249": "Südsudan" },
  { "82": "Südkorea" },
  { "7": "Südossetien" },
  { "597": "Suriname" },
  { "268": "Swasiland" },
  { "963": "Syrien" },
  { "992": "Tadschikistan" },
  { "886": "Taiwan" },
  { "255": "Tansania" },
  { "66": "Thailand" },
  { "228": "Togo" },
  { "690": "Tokelau" },
  { "676": "Tonga" },
  { "1": "Trinidad und Tobago" },
  { "235": "Tschad" },
  { "420": "Tschechien" },
  { "216": "Tunesien" },
  { "90": "Türkei" },
  { "90": "Türkische Republik Nordzypern" },
  { "993": "Turkmenistan" },
  { "1": "Turks- und Caicosinseln" },
  { "688": "Tuvalu" },
  { "256": "Uganda" },
  { "380": "Ukraine" },
  { "36": "Ungarn" },
  { "598": "Uruguay" },
  { "1": "Vereinigte Staaten USA" },
  { "998": "Usbekistan" },
  { "678": "Vanuatu" },
  { "58": "Venezuela" },
  { "971": "Vereinigte Arabische Emirate" },
  { "44": "Vereinigtes Königreich" },
  { "84": "Vietnam" },
  { "681": "Wallis und Futuna" },
  { "61": "Weihnachtsinsel" },
  { "236": "Zentralafrikanische Republik" },
  { "357": "Zypern" }
];
