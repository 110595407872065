/* eslint-disable */
import { Analytics } from "aws-amplify";
//import dataQuestions from '../../data/questions.js'
import dataGeneral from "../../data/general.js";
import dataPhonePrimaries from "../../data/form/phone_primaries.js";
import dataCountries from "../../data/form/countries.js";
import dataStates from "../../data/form/states.js";
import dataMaritalStatus from "../../data/form/marital_status.js";
import dataOccupations from "../../data/form/occupations.js";
import dataIndustries from "../../data/form/industries.js";
//import * as queries from './../../graphql/queries'
//import * as mutations from './../../graphql/mutations'
//import * as subscriptions from './../../graphql/subscriptions'
import question1 from "./../../data/questions/question-1.json";
import question2 from "./../../data/questions/question-2.json";
import question3 from "./../../data/questions/question-3.json";
import question4 from "./../../data/questions/question-4.json";
import question5 from "./../../data/questions/question-5.json";
import question6 from "./../../data/questions/question-6.json";
import question7 from "./../../data/questions/question-7.json";
import question8 from "./../../data/questions/question-8.json";
import question9 from "./../../data/questions/question-9.json";
import question10 from "./../../data/questions/question-10.json";
import question11 from "./../../data/questions/question-11.json";
import question12 from "./../../data/questions/question-12.json";
import question13 from "./../../data/questions/question-13.json";
import question14 from "./../../data/questions/question-14.json";
import question15 from "./../../data/questions/question-15.json";
import question16 from "./../../data/questions/question-16.json";
import question17 from "./../../data/questions/question-17.json";
import question18 from "./../../data/questions/question-18.json";
import question19 from "./../../data/questions/question-19.json";
import question20 from "./../../data/questions/question-20.json";
import question21 from "./../../data/questions/question-21.json";
import question22 from "./../../data/questions/question-22.json";
import question23 from "./../../data/questions/question-23.json";
import question99 from "./../../data/questions/question-99.json";
import question100 from "./../../data/questions/question-100.json";
import question101 from "./../../data/questions/question-101.json";
import {
  mapping,
  retirementMapping,
  getCorrectStrategy
} from "../utils/valuemapping";

const quest = [
  question1,
  question2,
  question3,
  question4,
  question5,
  question6,
  question7,
  question8,
  question9,
  question10,
  question11,
  question12,
  question13,
  question14,
  question15,
  question16,
  question17,
  question18,
  question19,
  question20,
  question21,
  question22,
  question23,
  question99,
  question100,
  question101
];
const questAddProduct = [question99, question100, question101];
const questNewBpSingle = [
  question3,
  question4,
  question5,
  question6,
  question7,
  question8,
  question9,
  question10,
  question11,
  question12,
  question13,
  question14,
  question15,
  question16,
  question17,
  question18,
  question19,
  question20,
  question21,
  question22,
  question23,
  question99,
  question100,
  question101
];
const questNewBpJoined = [
  question3,
  question4,
  question5,
  question6,
  question7,
  question8,
  question9,
  question10,
  question11,
  question12,
  question13,
  question14,
  question15,
  question16,
  question17,
  question18,
  question19,
  question20,
  question21,
  question22,
  question23,
  question99,
  question100,
  question101
];
const questNewBPChild = [
  question2,
  question3,
  question4,
  question5,
  question6,
  question7,
  question8,
  question9,
  question10,
  question11,
  question12,
  question13,
  question14,
  question15,
  question16,
  question17,
  question18,
  question19,
  question20,
  question21,
  question22,
  question23,
  question99,
  question100,
  question101
];
const questNewBPChildWithParentsCountStepDone = [
  question3,
  question4,
  question5,
  question6,
  question7,
  question8,
  question9,
  question10,
  question11,
  question12,
  question13,
  question14,
  question15,
  question16,
  question17,
  question18,
  question19,
  question20,
  question21,
  question22,
  question23,
  question99,
  question100,
  question101
];

/**
 * filters array of questions based on userData and current index , if a question has a requires a condition, check userdata to see if those conditions are met
 * @method
 * @param {Question[]} questions - array of questions
 * @param {Number} idx - index of current question
 * @param {{userData}} filters - userData
 * @returns {Question[]}} return array of questions
 */
const filterQuestions = (questions, idx, filters) => {
  const slicedArray = questions.slice(idx);
  return slicedArray.filter(question => {
    const values = Object.values(question.stateRequired);
    const keys = Object.keys(question.stateRequired);
    if (keys.length === 0) {
      return true;
    } else if (keys.length > 0) {
      return keys.reduce(
        (acc, key) =>
          acc && (values.includes(filters[key]) || filters[key] === null),
        true
      );
    }
  });
};

/**
 * Returns an array of answers with and updated entry
 * @method
 * @param {Answer[]} answers - array of current answers
 * @param {{}} entry - last entry made by user
 * @returns {Answer[]} array of updated answers
 */
const filterAnswers = (answers, entry) => {
  const repeatedIdx = answers.findIndex(x => x.index == entry.index);
  return answers.length < 1
    ? [...answers, entry]
    : repeatedIdx === -1
    ? [...answers, entry]
    : answers
        .slice(0, repeatedIdx)
        .concat(entry, answers.slice(repeatedIdx + 1));
};

// methods and constant used for calculating offers.
//+++++++++++++++++++

const preliminaryPts = results => Math.min(...results);
const moreRiskPts = (pts, arr, weightedLength) =>
  Math.min(pts + Math.max(10, riskCalibration(arr, weightedLength)), 100);
const lessRiskPts = (pts, arr, weightedLength) =>
  Math.max(10, pts - Math.max(10, riskCalibration(arr, weightedLength)));

const yeeld = result => {
  //console.log(minRisk , premRisk , result/100)
  return minRisk + (premRisk * result) / 100;
};
const volatility = result => (result / 100) * maxRisk;

const winPercent = r => (yeeld(r) + volatility(r)) * 100;
const losePercent = r => (yeeld(r) - volatility(r)) * 100;

const finalResult = (choice, preliminaryPts) =>
  0.5 * choice + 0.5 * preliminaryPts;

const mean = (arr, weightedLength) => {
  //console.log('mean',arr, weightedLength,sum(arr) / weightedLength)
  return sum(arr) / weightedLength;
};
const sum = arr => arr.reduce((acc, curr) => acc + curr, 0);
const sumSquareDistance = (arr, weightedLength) => {
  //console.log('sumSquareDistance',arr,weightedLength,arr.reduce((acc,  curr)=> acc + Math.pow(curr - mean(arr, weightedLength), 2), 0) )
  return arr.reduce(
    (acc, curr) => acc + Math.pow(curr - mean(arr, weightedLength), 2),
    0
  );
};
const standardDeviation = (arr, weightedLength) => {
  //console.log('standardDeviation',sumSquareDistance(arr, weightedLength),weightedLength,Math.sqrt(sumSquareDistance(arr, weightedLength) / weightedLength))
  return Math.sqrt(sumSquareDistance(arr, weightedLength) / weightedLength);
};
const inconsistencyThreshold = 0.3;
const premRisk = 0.06;
const maxRisk = 0.22;
const minRisk = 0;
const riskCalibration = (arr, weightedLength) =>
  Math.max(10, 60 * standardDeviation(arr, weightedLength));

//+++++++++++++++
/**
 * Returns an array of offers calculated based on the previous answers
 * @method
 * @param {Answer[]} answers - array of answers
 * @param {{}} userData - userdata from state
 * @param {'graph'|'real'} type - what the returned data will be used to represent
 * @returns{{index:number; yield:number; volatility:number; winPer:number; win:number; losePer:number; lose:number; finalResult:{}; stdDeviation:number}[]} array of offers
 */
const calcOffer = (answers, userData, type) => {
  // preference and capacity values are nested differently as they relate to two different types of state update
  // preference data comes from question selection whereas capacity comes from input

  const preferenceFilter = answers
    .filter(x => x.category == "preference")
    .filter(x => x.value !== false)
    .map(x => x.value);
  //console.log( 'preferenceFilter' ,  preferenceFilter)
  const preference =
    preferenceFilter.reduce((acc, each) => acc + each * 100, 0) /
    Math.max(preferenceFilter.length, 1);
  //console.log( 'preference' ,  preference)
  //answers that have the "capacity" category should have weight to them, this affects future calculations,
  const capacityFilter = answers
    .filter(x => x.category == "capacity")
    .map(x => {
      return x.value.map(obj => obj.value);
    });
  //console.log('capacityFilter',capacityFilter)
  const capacityWeight = answers
    .filter(x => x.category == "capacity")
    .map(x => {
      return x.value.map(obj => obj.weight);
    });

  //console.log('capacityWeight',capacityWeight)
  const capacityArray = capacityFilter.reduce(
    (acc, each) => acc.concat(each),
    []
  );
  //console.log('capacityArray',capacityArray)
  const capacityWeightValue = capacityWeight.reduce(
    (acc, each) => acc.concat(each),
    []
  );
  //console.log('capacityWeightValue',capacityWeightValue)
  const capacityWeightSum = capacityWeightValue.reduce(
    (acc, curr) => acc + curr,
    0
  );
  //console.log('capacityWeightSum',capacityWeightSum,capacityArray)
  const capacity =
    capacityArray.reduce((acc, curr) => acc + curr * 100, 0) /
    capacityWeightSum;
  //console.log('capacity',capacity)
  const concatenatedPoints = preferenceFilter.concat(capacityArray);
  const capacityLength = capacityArray.length;
  //console.log()
  const weightedLength = preferenceFilter.length + capacityLength;
  //const weightedLength = preferenceFilter.length + capacityWeightSum
  //console.log( 'weightedLength',preferenceFilter ,preferenceFilter.length, capacityWeightSum)
  const realPrel = preliminaryPts([capacity, preference]);
  //console.log('realPrel preliminaryPts(',capacity,preference)
  const prel = Math.max(realPrel, 18);
  const pts = [
    lessRiskPts(prel, concatenatedPoints, weightedLength),
    prel,
    moreRiskPts(prel, concatenatedPoints, weightedLength)
  ];

  //console.log(pts)
  let initial_amount = userData.initial_amount;
  if (type === "graph") {
    //console.log('calcOffer',type,prel)
    initial_amount = Math.max(1000, userData.initial_amount);
  } else {
    //console.log('calcOffer',type,realPrel)
  }
  //console.log('pts',pts)
  return pts.map((each, idx) => ({
    index: idx,
    yield: yeeld(each),
    volatility: volatility(each),
    winPer: winPercent(each),
    win: (parseInt(initial_amount) * winPercent(each)) / 100,
    losePer: losePercent(each),
    lose: (parseInt(initial_amount) * losePercent(each)) / 100,
    finalResult:
      type === "graph" ? finalResult(each, prel) : finalResult(each, realPrel),
    stdDeviation: standardDeviation(concatenatedPoints, weightedLength)
  }));
};
/**
 * Returns an arra of filtered conditions based on userData
 * @method
 * @param {{}} userData - userData
 * @param {Condition[]} array - array of conditions
 * @returns {Filetered[]} filtered array
 */
const filterConds = (array, userData) => {
  return array.filter(condition => {
    if (userData.account_type === condition.accountType) {
      if (userData.wphg_investment_purpose === null) {
        return true;
      } else if (userData.wphg_investment_purpose == "retirement") {
        return condition.category === "AV.AV";
      } else if (userData.wphg_investment_purpose === "general investment") {
        if (userData.wphg_sustainability === false) {
          return condition.category === "VV.VV";
        } else if (userData.wphg_sustainability === true) {
          return condition.category === "VV.NH";
        } else {
          if (
            condition.category === "VV.VV" ||
            condition.category === "VV.NH"
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else if (
      condition.accountType === "childAccount" &&
      userData.childAccount
    ) {
      if (userData.wphg_investment_purpose === null) {
        return true;
      } else if (userData.wphg_investment_purpose === "retirement") {
        return condition.category === "AV.AV";
      } else if (userData.wphg_investment_purpose === "general investment") {
        if (userData.wphg_sustainability === false) {
          return condition.category === "VV.VV";
        } else if (userData.wphg_sustainability === true) {
          return condition.category === "VV.NH";
        } else {
          if (
            condition.category === "VV.VV" ||
            condition.category === "VV.NH"
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
};
const initState = {
  user: {},
  person: {},
  depots: [],
  conditions: [],
  filteredConditions: [],
  userStatus: {
    activeProduct: null,
    showLoader: false,
    prevPath: false
  },
  //,questions:dataQuestions
  general: dataGeneral,
  answers: [],
  advisor: "quirion",
  messages: [],
  unreadedPostBoxElements: "0",
  formdata: {
    maritalStatus: dataMaritalStatus,
    occupations: dataOccupations,
    phonePrimaries: dataPhonePrimaries,
    countries: dataCountries,
    states: dataStates,
    industries: dataIndustries
  },
  assets: [],
  questions: quest,
  referenceQuestions: quest,
  roboData: {
    mapping: {
      default: mapping,
      retirement: retirementMapping
    },
    strategy: null,
    previousQuestions: [],
    previousAnswer: [],
    referenceData: {},
    userData: {
      account_type: null,
      childAccount: null,
      wphg_investment_purpose: null,
      wphg_holding_period: null,
      time_to_retirement: null,
      wphg_sustainability: null,
      wphg_age: null,
      initial_amount: null,
      savingplan_rate: null,
      wphg_risk_preference: null,
      wphg_job_description: null,
      wphg_additional_questions: null,
      wphg_knowledge_self: null,
      wphg_stock_experience: null,
      MultipleChoice: null,
      wphg_risk_composure: null,
      wphg_risk_lottery: null,
      wphg_risk_perception: null,
      wphg_job_evaluation: null,
      wphg_liquid_assets: null,
      wphg_illiquid_assets: null,
      wphg_liabilities: null,
      wphg_total_assets: null,
      wphg_total_assets_evaluation: null,
      wphg_liquid_assets_evaluation: null,
      wphg_net_income: null,
      wphg_expenses: null,
      wphg_savings_rate: null,
      wphg_drawdown_capacity: null,
      wphg_inconsistent: null,
      stdDeviation: null,
      resetPrompt: null,
      wphg_final_choice: null,
      wphg_knowledge_risk: null,
      wphg_knowledge_vv: null,
      advisor: null,
      user_choice_strategy: null,
      user_choice_strategy_key: null,
      user_choice_sustained: null,
      user_choice_comfort: null,
      user_choice_strategy_changes: null,
      user_choice_condition_id: null
    },
    finalOptionsGraph: [],
    finalOptionsReal: []
  },
  historicData: {},
  newProduct: {}
};

const rootReducer = (state = initState, action) => {
  //console.log('rootReducer',action.type, action)
  if (action.type === "SHOW_LOADER") {
    return {
      ...state,
      userStatus: {
        ...state.userStatus,
        showLoader: action.status
      }
    };
  }

  if (action.type === "STORE_LAST_LOCATION") {
    if (action.myLocation && action.myLocation.pathname) {
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          prevPath: action.myLocation.pathname
        }
      };
    } else {
      return {
        ...state
      };
    }
  }

  if (action.type === "UPDATE_USER_LAST_ACTIVITY") {
    //console.log('UPDATE_USER_LAST_ACTIVITY')
    localStorage.setItem("lastActivityAt", Math.floor(Date.now() / 1000));

    return {
      ...state,
      userStatus: {
        ...state.userStatus,
        lastActivityAt: Math.floor(Date.now() / 1000)
      }
    };
  }

  if (action.type === "SET_USER_STATUS") {
    return {
      ...state,
      userStatus: {
        ...state,
        activeProduct: action.activeProduct
      }
    };
  }

  if (action.type === "SET_CLIENTTYPE") {
    // why is there jQuery appending a class to the DOM is the redux store??
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#Do_not_ever_use_eval!
    // this should be it's own functionality / component
    if (action.clientType != "quirion") {
      // robo urls are generated without advisor, so it would fall back to quirion everytime
      // let advisornumber = eval('state.general.client_type.'+action.clientType+'.advisor')
      let advisornumber = state.general.client_type[action.Clientype].adivsor;
      if (advisornumber > 100 && advisornumber < 200) {
        // quiron but not quirion standard
        $("body").addClass(action.clientType);
      } else if (advisornumber >= 200) {
        $("body").addClass(action.clientType);
        $("body").addClass("partner");
        //$('#logo_ani').hide()
        $("#logo").append(
          '<div class="logo"><img src="/images/logo_' +
            advisornumber +
            '.svg" /></div>'
        );
      }
    }

    return {
      ...state,
      advisor: action.clientType
    };
  }

  if (action.type === "SET_NEWPRODUCT") {
    let newProduct = {};

    if (action.newProductState) {
      newProduct = state.newProduct;
      eval(
        "newProduct.step_" +
          action.newProductState.step +
          " = action.newProductState"
      );
    }

    return {
      ...state,
      newProduct: newProduct
    };
  }

  if (action.type === "LOADHISTORICDATA_GAP") {
    let historicData = {};
    if (state.historicData.vv) {
      historicData.vv = state.historicData.vv;
    }
    if (state.historicData.av) {
      historicData.av = state.historicData.av;
    }

    historicData.vv = action.response.data;

    return {
      ...state,
      historicData: historicData
    };
  }

  if (action.type === "SET_ROBO_QUESTIONS") {
    let conf = action.conf;
    if (action.newStrategy === false) {
      // addBP
      let questions = quest;
      if (action.conf.accountType === "singleAccount") {
        questions = questNewBpSingle;
      } else if (action.conf.accountType === "joinedAccount") {
        questions = questNewBpJoined;
      } else if (action.conf.accountType === "childAccount") {
        questions = questNewBPChild;
        return {
          ...state,
          questions: questions,
          roboData: {
            ...state.roboData,
            previousQuestions: state.roboData.previousQuestions.slice(
              state.roboData.previousQuestions.length + 1
            ),
            userData: {
              ...state.roboData.userData,
              ...Object.keys(state.roboData.userData).map(
                entry => (state.roboData.userData[entry] = null)
              ),
              childAccount: true
            },
            previousAnswer: state.roboData.previousAnswer.slice(
              state.roboData.previousAnswer.length + 1
            ),
            referenceData: {}
          }
        };
      }
      return {
        ...state,
        questions: questions,
        roboData: {
          ...state.roboData,
          previousQuestions: state.roboData.previousQuestions.slice(
            state.roboData.previousQuestions.length + 1
          ),
          userData: {
            ...state.roboData.userData,
            ...Object.keys(state.roboData.userData).map(
              entry => (state.roboData.userData[entry] = null)
            ),
            account_type: action.conf.accountType
          },
          previousAnswer: state.roboData.previousAnswer.slice(
            state.roboData.previousAnswer.length + 1
          ),
          referenceData: {}
        }
      };
    } else if (action.newStrategy && !conf.needWphgData) {
      let wphgData = JSON.parse(JSON.parse(conf.bp.wphg));

      if (conf.type === "av") {
        wphgData.wphg_investment_purpose = "retirement";
        wphgData.wphg_sustainability = false;
        wphgData.wphg_holding_period = null;
        if (conf.person && conf.person.birthday) {
          let arrBirthday = conf.person.birthday.split("-");
          let now = new Date();
          wphgData.wphg_age_retirement =
            parseInt(arrBirthday[0]) + 67 - now.getFullYear();
        } else {
          wphgData.wphg_age_retirement = 40;
        }
      } else if (conf.type === "nh") {
        wphgData.wphg_sustainability = true;
      } else {
        // gap
        wphgData.wphg_sustainability = false;
      }

      if (wphgData.wphg_investment_purpose === "general investment") {
      } else if (wphgData.wphg_investment_purpose === "retirement") {
      }

      return {
        ...state,
        questions: questAddProduct,
        roboData: {
          ...state.roboData,
          userData: wphgData,
          add2Bp: conf.bp.id
        }
      };
    } else {
      // ganzen robo presentieren, da neue wphg daten notwendig
      //console.log('nicht valide ',action.conf.bp.accountType,state.roboData.userData)
      let questions = quest;
      if (action.conf.bp.accountType === "singleAccount") {
        questions = questNewBpSingle;
      } else if (action.conf.bp.accountType === "joinedAccount") {
        questions = questNewBpJoined;
      } else if (action.conf.bp.accountType === "childAccount") {
        questions = questNewBPChild;
      } else if (action.conf.bp.accountType === "childSingleAccount") {
        questions = questNewBPChildWithParentsCountStepDone;
      } else if (action.conf.bp.accountType === "childJoinedAccount") {
        questions = questNewBPChildWithParentsCountStepDone;
      }

      return {
        ...state,
        questions: questions,
        roboData: {
          ...state.roboData,
          previousQuestions: state.roboData.previousQuestions.slice(
            state.roboData.previousQuestions.length + 1
          ),
          userData: {
            ...state.roboData.userData,
            ...Object.keys(state.roboData.userData).map(
              entry => (state.roboData.userData[entry] = null)
            ),
            account_type: action.conf.bp.accountType
          },
          previousAnswer: state.roboData.previousAnswer.slice(
            state.roboData.previousAnswer.length + 1
          ),
          referenceData: {},
          add2Bp: conf.bp.id
        }
      };
    }
  }

  if (action.type === "SET_ROBO_USERDATA") {
    console.log("SET_ROBO_USERDATA", action);

    return {
      ...state
    };
  }

  if (action.type === "LOADHISTORICDATA_AV") {
    let historicData = {};
    /*
        if(state.historicData.nh){
            historicData.nh = state.historicData.nh
        }*/
    if (state.historicData.vv) {
      historicData.vv = state.historicData.vv;
    }
    if (state.historicData.av) {
      historicData.av = state.historicData.av;
    }

    historicData.av = action.response.data;

    return {
      ...state,
      historicData: historicData
    };
  }
  /*
    if(action.type === 'LOADHISTORICDATA_NH'){
        
        let historicData = {}
        if(state.historicData.nh){
            historicData.nh = state.historicData.nh
        }
        if(state.historicData.vv){
            historicData.vv = state.historicData.vv
        }
        if(state.historicData.av){
            historicData.av = state.historicData.av
        }
        
        historicData.nh = action.response.data
        
        return {
            ...state,
            historicData: historicData
        }
    }
*/

  if (action.type === "GETUNREADEDPOSTBOX_OK") {
    let unreadedDocuments = 0;
    if (action.response.data.getPerson) {
      if (action.response.data.getPerson.postbox.items.length > 9) {
        unreadedDocuments = "9+";
      } else {
        unreadedDocuments = parseInt(
          action.response.data.getPerson.postbox.items.length
        );
      }
    }
    return {
      ...state,
      unreadedPostBoxElements: unreadedDocuments
    };
  }

  if (action.type === "GETCONDITIONS_OK") {
    return {
      ...state,
      conditions: action.response.data,
      filteredConditions: action.response.data
    };
  }

  if (action.type === "GETUSER_OK") {
    let userObj = {};
    userObj.loaded = true;
    userObj.cognitoId = action.response.username;
    userObj.data = action.response;

    return {
      ...state,
      user: userObj
    };
  }
  if (action.type === "GETPERSON_OK") {
    let personData = {};
    if (action.response.data && action.response.data.getPerson) {
      personData = action.response.data.getPerson;
      let depots = [];
      depots = personData.businessPartners.items.length
        ? personData.businessPartners.items.map(depot => {
            if (depot["waitForBirthCertificate"]) {
              depot["businessPartner"].waitForBirthCertificate =
                depot["waitForBirthCertificate"];
            }
            return depot["businessPartner"];
          })
        : "";
      return {
        ...state,
        depots: depots,
        person: personData
      };
    } else if (action.response.data && action.response.data.updatePerson) {
      personData = action.response.data.updatePerson;
      return {
        ...state,
        person: personData
      };
    } else if (
      action.response.value &&
      action.response.value.data.onUpdatePerson
    ) {
      personData = action.response.value.data.onUpdatePerson;
      return {
        ...state,
        person: personData
      };
    }
  }
  if (action.type === "GETBPS_OK") {
    return {
      ...state,
      depots: action.response.data.listBusinessPartners.items
    };
  }
  if (action.type === "GETUSER_ERROR") {
    console.log("GETUSER_ERROR");
    window.localStorage.clear();
    return {
      ...state,
      user: {}
    };
  }
  if (action.type === "LOGIN_OK") {
    let userObj = {
      token: action.response.data.access_token,
      lastConnection: Date.now(),
      loaded: false
    };
    localStorage.setItem("userToken", userObj.token);
    return {
      ...state,
      user: userObj
    };
  }
  if (action.type === "LOGOUT") {
    window.localStorage.clear();
    return {
      ...state,
      user: {},
      depots: []
    };
  }
  if (action.type === "DELETE_DEPOT") {
    let newDepots = state.depots.filter(depot => {
      return action.id !== depot.id;
    });
    return {
      ...state,
      depots: newDepots
    };
  }
  if (action.type === "MESSAGE_ADD") {
    let localMessage = [];
    state.messages.forEach(function(element) {
      localMessage.push(element);
    });

    localMessage.push(action.messageTyp);

    return {
      ...state,
      messages: localMessage
    };
  }
  if (action.type === "UPDATE_USERDATA") {
    return {
      ...state,
      roboData: {
        ...state.roboData,
        userData:
          action.values.length > 1
            ? Object.assign({}, state.roboData.userData, ...action.values)
            : Object.assign({}, state.roboData.userData, action.values),
        referenceData:
          action.values.length > 1
            ? Object.assign({}, state.roboData.referenceData, ...action.values)
            : Object.assign({}, state.roboData.referenceData, action.values)
      }
    };
  }
  if (action.type === "UPDATE_ANSWER") {
    let analyticsData = {};
    if (action.option.label) {
      analyticsData = {
        param: action.question.param,
        answer: action.option.label.toString()
      };
    } else {
      analyticsData = { param: action.question.param, answer: "passed" };
    }
    //console.log('UPDATE_ANSWER',analyticsData)
    Analytics.record({ name: "Answer", attributes: analyticsData });

    return {
      ...state,
      roboData: {
        ...state.roboData,
        previousQuestions: [
          ...state.roboData.previousQuestions,
          action.question
        ],
        previousAnswer: filterAnswers(state.roboData.previousAnswer, {
          index: action.question.index,
          option: action.option,
          category: action.question.category,
          value: action.option.value,
          urlLabel: action.option.urlLabel,
          length: action.question.options.length,
          weight: action.question.weight
        })
      }
    };
  }
  if (action.type === "UPDATE_INPUT") {
    if (action.values) {
      for (const [key, value] of Object.entries(action.values)) {
        if (value) {
          if (parseInt(key).toString() === key.toString()) {
            // key ist nicht wie erwartet ein String sondern ein Integer
            for (const [key2, value2] of Object.entries(value)) {
              if (key2 !== "weight" && key2 !== "value") {
                let analyticsData = {
                  param: key2,
                  answer: value2 === null ? "0" : value2.toString()
                };
                //console.log('UPDATE_INPUT',analyticsData)
                Analytics.record({ name: "Answer", attributes: analyticsData });
              }
            }
          } else {
            let analyticsData = { param: key, answer: value.toString() };
            //console.log('UPDATE_INPUT',analyticsData)
            Analytics.record({ name: "Answer", attributes: analyticsData });
          }
        }
      }
    }

    return {
      ...state,
      roboData: {
        ...state.roboData,
        previousQuestions: [
          ...state.roboData.previousQuestions,
          action.question
        ],
        previousAnswer: !action.question.category
          ? filterAnswers(state.roboData.previousAnswer, {
              index: action.question.index,
              option: action.values
            })
          : filterAnswers(state.roboData.previousAnswer, {
              index: action.question.index,
              option: action.values,
              category: action.question.category,
              value: action.values.filter(x => x.weight),
              length: action.values.length
            })
      }
    };
  }
  if (action.type === "UPDATE_CONDITIONS") {
    return {
      ...state,
      filteredConditions: [
        ...filterConds(state.conditions, state.roboData.userData)
      ]
    };
  }
  if (action.type === "UPDATE_QUESTIONS") {
    return {
      ...state,
      questions: filterQuestions(
        state.referenceQuestions,
        action.question.index,
        state.roboData.userData
      )
    };
  }
  if (action.type === "NEXT_QUESTION") {
    return {
      ...state,
      questions: state.questions.slice(1)
    };
  }
  if (action.type === "PREVIOUS_QUESTION") {
    return {
      ...state,
      questions: state.roboData.previousQuestions.slice(-1),
      roboData: {
        ...state.roboData,
        previousQuestions: [
          ...(state.roboData.previousQuestions.length > 0
            ? state.roboData.previousQuestions.slice(
                0,
                state.roboData.previousQuestions.length - 1
              )
            : state.roboData.previousQuestions.slice(1))
        ],
        userData: {
          ...state.roboData.userData,
          ...action.stateAffected.map(x => {
            state.roboData.userData[x] = null;
          })
        },
        previousAnswer: [
          ...(state.roboData.previousAnswer.length > 0
            ? state.roboData.previousAnswer.slice(
                0,
                state.roboData.previousQuestions.length
              )
            : state.roboData.previousAnswer.slice(1))
        ]
      }
    };
  }
  if (action.type === "CALCULATE_ANSWER") {
    //console.log('setze finalOptionsReal',[...calcOffer(state.roboData.previousAnswer, state.roboData.userData, 'real')])
    return {
      ...state,
      roboData: {
        ...state.roboData,
        finalOptionsGraph: [
          ...calcOffer(
            state.roboData.previousAnswer,
            state.roboData.userData,
            "graph"
          )
        ],
        finalOptionsReal: [
          ...calcOffer(
            state.roboData.previousAnswer,
            state.roboData.userData,
            "real"
          )
        ]
      }
    };
  }
  if (action.type === "GET_STD_DEVIATION") {
    //console.log('setze stdDeviation',state.roboData.finalOptionsReal[1].stdDeviation)
    return {
      ...state,
      roboData: {
        ...state.roboData,
        userData: {
          ...state.roboData.userData,
          stdDeviation: state.roboData.finalOptionsReal[1].stdDeviation
            ? state.roboData.finalOptionsReal[1].stdDeviation
            : null,
          resetPrompt:
            state.roboData.finalOptionsReal[1].stdDeviation > 0.3 ? true : false
        }
      }
    };
  }
  if (action.type === "CALCULATE_STRATEGY") {
    return {
      ...state,
      roboData: {
        ...state.roboData,
        ...getCorrectStrategy(state.roboData.userData, state.roboData.mapping)
      }
    };
  }
  if (action.type === "RESET") {
    return {
      ...state,
      questions: [...state.referenceQuestions],
      roboData: {
        ...state.roboData,
        previousQuestions: state.roboData.previousQuestions.slice(
          state.roboData.previousQuestions.length + 1
        ),
        userData: {
          ...state.roboData.userData,
          ...Object.keys(state.roboData.userData).map(
            entry => (state.roboData.userData[entry] = null)
          )
        },
        previousAnswer: state.roboData.previousAnswer.slice(
          state.roboData.previousAnswer.length + 1
        ),
        referenceData: {},
        add2Bp: false
      }
    };
  }
  return state;
};

export default rootReducer;
