// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id:
    "eu-central-1:4fb458b6-8f9c-4b1f-87af-818b09a83451",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_gTUDX5j64",
  aws_user_pools_web_client_id: "4jtcv818f3pfhdoo1tpb7tbahq",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://myiv6qktzfcmbcf4ntzbo4cuwe.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cloud_logic_custom: [
    {
      name: "quirionrest",
      endpoint:
        "https://hhxpcb4afl.execute-api.eu-central-1.amazonaws.com/prod",
      region: "eu-central-1"
    }
  ],
  aws_mobile_analytics_app_id: "3868160ec63c4d8cbe3a9a161a9551fa",
  aws_mobile_analytics_app_region: "eu-central-1",
  aws_user_files_s3_bucket: "quirion-postfach-prod",
  aws_user_files_s3_bucket_region: "eu-central-1"
};

export default awsmobile;
