import React from "react";
import { withRouter } from "react-router-dom";
import { Storage } from "aws-amplify";
import aws_config_dev from "./../../aws-exports-dev";
import aws_config_test from "./../../aws-exports-test";
import aws_config_prod from "./../../aws-exports-prod";
import externalLinks from "./../../data/externalLinks";
import marketingAktionen from "./../../data/marketingAktionen";
import helperProduct from "./helper_product";
import helperBank from "./helper_bank";
import axios from "axios";

const helperGlobal = {
  async openPostboxDocument(documentKey, expireTime = 360) {
    // eslint-disable-next-line
    Storage.get(documentKey, { level: "private", expires: expireTime })
      .then(result => {
        let url = result;
        var win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log("err", err);
      });
  },
  getRestApiUrl() {
    let apiUrl = false;

    let aws_config = aws_config_dev;

    if (process.env.NODE_ENV && process.env.REACT_APP_BRANCH === "master") {
      aws_config = aws_config_prod;
    } else if (
      process.env.NODE_ENV &&
      process.env.REACT_APP_BRANCH === "test"
    ) {
      aws_config = aws_config_test;
    }

    if (aws_config.aws_cloud_logic_custom[0]) {
      apiUrl = aws_config.aws_cloud_logic_custom[0].endpoint + "/";
    }
    return apiUrl;
  },
  getMarketingAktionen(accountType, businessCase, state, onclickHandler) {
    console.log(accountType);
    let dateNow = new Date();
    let validActions = [];
    for (let i = 0; i < marketingAktionen.length; i++) {
      let aktion = marketingAktionen[i];
      let dateAktionStart = Date.parse(aktion.start);
      let dateAktionEnd = Date.parse(aktion.end);
      if (dateNow > dateAktionStart && dateNow < dateAktionEnd) {
        if (
          aktion.accountTypes.indexOf(accountType) > -1 &&
          aktion.cases.indexOf(businessCase) > -1
        ) {
          validActions.push(aktion);
        }
      }
    }
    let arrOut = [];
    if (validActions.length > 0) {
      arrOut.push(
        <li key="header" className="full extraTopMargin">
          {/* Uses the first aktion's headline out of one or several "validActions" for all "validActions". */}
          <h2>{validActions[0].headline}</h2>
        </li>
      );
      for (let i = 0; i < validActions.length; i++) {
        let statusSelect = "";
        if (state["marketingAktion" + i] === "true") {
          statusSelect = "selected";
        }
        arrOut.push(
          <li key={"element-" + i} className="full">
            <div className="checkbox_container">
              <div
                className={statusSelect + " checkbox marketingAktion"}
                data={validActions[i].actionId}
                onClick={onclickHandler}
                name={"marketingAktion" + i}
                value="true"
              >
                <label
                  className="checkbox"
                  name={"marketingAktion" + i}
                  value="true"
                  dangerouslySetInnerHTML={{ __html: validActions[i].text }}
                />
              </div>
            </div>
          </li>
        );
      }
    }
    return <ul className="marketing aktionen full">{arrOut}</ul>;
  },
  externalLink(type, getTitle = false) {
    for (const [key, link] of Object.entries(externalLinks)) {
      //console.log(key, value);
      if (key === type) {
        if (getTitle) {
          return link.title;
        } else {
          if (link.target) {
            window.open(link.url, link.target);
          } else {
            window.open(link.url, "_blank");
          }
        }
      }
    }
  },
  getBaretinfos(person, depot) {
    let isPremiumSupport = false;

    if (person && person.consultant) {
      let myProducts = helperProduct.getMyProducts(depot, null, false);
      for (let i = 0; i < myProducts.length; i++) {
        if (
          myProducts[i].conditions.items &&
          myProducts[i].conditions.items[0] &&
          myProducts[i].conditions.items[0].condition &&
          myProducts[i].conditions.items[0].condition.consulting
        ) {
          isPremiumSupport = true;
        }
      }
    }

    if (person && person.id) {
      if (isPremiumSupport) {
        //console.log(person.consultant)
        let picLink =
          "/images/berater/" + person.consultant.s3BucketPicLink + ".png";
        return (
          <li className="beraterInfos premium">
            <div>
              <div className="head">Premium-Support</div>
              <div>
                <div className="berater">
                  <div className="image">
                    <img
                      src={picLink}
                      alt={
                        person.consultant.firstName +
                        " " +
                        person.consultant.lastName
                      }
                    />
                  </div>
                  <div className="name">
                    Ihr persönlicher Ansprechpartner vor Ort:
                    <strong>
                      {person.consultant.firstName +
                        " " +
                        person.consultant.lastName}
                    </strong>
                  </div>
                </div>
                <div className="table">
                  <div>
                    <div>Telefon:</div>
                    <div>
                      <a href={"tel:" + person.consultant.phoneNumber}>
                        {person.consultant.phoneNumber}
                      </a>
                    </div>
                  </div>
                </div>
                <a href={"mailto:" + person.consultant.email}>
                  {person.consultant.email}
                </a>
                <br />
                <br />
                Niederlassung:
                <br />
                {person.consultant.ndl.name}
                <br />
                {person.consultant.ndl.street} {person.consultant.ndl.streetNr}
                <br />
                {person.consultant.ndl.zip} {person.consultant.ndl.city}
              </div>
            </div>
            <div>
              <div className="head">
                Allgemeine Anfragen? Technische Probleme? Fragen zu unseren
                Produkten?
              </div>
              <div>
                <div className="table">
                  <div>
                    <div>Telefax:</div>
                    <div>+49 (0) 30 89021-414</div>
                  </div>
                  <div>
                    <div>Telefon:</div>
                    <div>
                      <a href={"tel:+49 (0) 30 89021-400"}>
                        +49 (0) 30 89021-400
                      </a>
                    </div>
                  </div>
                  <div>
                    <div>E-Mail:</div>
                    <div>
                      <a href={"mailto:info@quirion.de"}>info@quirion.de</a>
                    </div>
                  </div>
                </div>
                Mo. - Fr. von 9:00 - 18:00 Uhr
              </div>
            </div>
          </li>
        );
      } else {
        return (
          <li className="beraterInfos">
            <div className="head">
              Allgemeine Anfragen? Technische Probleme? Fragen zu unseren
              Produkten?
            </div>
            <div>
              <div className="table">
                <div>
                  <div>Telefax:</div>
                  <div>+49 (0) 30 89021-414</div>
                </div>
                <div>
                  <div>Telefon:</div>
                  <div>
                    <a href={"tel:+49 (0) 30 89021-400"}>
                      +49 (0) 30 89021-400
                    </a>
                  </div>
                </div>
                <div>
                  <div>E-Mail:</div>
                  <div>
                    <a href={"mailto:info@quirion.de"}>info@quirion.de</a>
                  </div>
                </div>
              </div>
              Mo. - Fr. von 9:00 - 18:00 Uhr
            </div>
          </li>
        );
      }
    }
    return <li></li>;
  },
  async openCostsheetDocument(url) {
    // eslint-disable-next-line
    axios.get(url).then(response => {
      window.location.href = response.data;
    });
  },
  getAnlagerichtlinienLink(
    product = null,
    roboData = null,
    newStrategy = null,
    linkText = null
  ) {
    //console.log(product,roboData,newStrategy)
    let nameAnlagerichtlinie = "";
    let arrStrategy = [];
    let conditionId = null;
    let category = false;
    let year = null;
    if (product) {
      if (product.strategy && product.category && product.conditions) {
        conditionId = product.conditions.items[0].condition.id;
        category = product.category; // standardfall es wurde ein product übergeben
        //  known cases 90:10(VV) ,moderat (AV) / Spardepot70 (30/50)(migrierte Spardepots)
        /*
                // Spardepot70 / Spardepot50 / Spardepot30 wird vorerst nicht erwartet
                if(product.strategy.toString().toLowerCase().indexOf('spardepot') > -1){
                    let tmpArrStrategy = product.strategy.toString().toLowerCase().split('spardepot')
                    arrStrategy.push(tmpArrStrategy[1])
                    arrStrategy.push((100-tmpArrStrategy[1]).toString())
                }
                else{*/
        arrStrategy = product.strategy.split(":");
        //}
      }
    } else if (
      roboData &&
      roboData.userData &&
      roboData.userData.user_choice_strategy_key &&
      roboData.userData.user_choice_condition_id
    ) {
      // sonderfall es wurden roboDaten übergeben
      conditionId = roboData.userData.user_choice_condition_id;
      category = roboData.userData.user_choice_condition_id.split("_")[1];
      arrStrategy = roboData.userData.user_choice_strategy_key.split(":");
    }

    if (newStrategy) {
      if (category === "AV.AV") {
        arrStrategy = [newStrategy];
      } else {
        arrStrategy = [newStrategy, 100 - newStrategy];
      }
    }

    if (category) {
      switch (category) {
        case "VV.VV":
          nameAnlagerichtlinie =
            "Anlagerichtlinie Globales Portfolio " +
            arrStrategy[0] +
            " % Aktien / " +
            arrStrategy[1] +
            " % Anleihen";
          break;
        case "VV.NH":
          nameAnlagerichtlinie =
            "Anlagerichtlinie Nachhaltiges Portfolio " +
            arrStrategy[0] +
            " % Aktien / " +
            arrStrategy[1] +
            " % Anleihen";
          break;
        case "AV.AV":
          let thisYear = new Date();
          if (product) {
            if (product.retirementYear) {
              year = product.retirementYear - thisYear.getFullYear();
            } else if (product.retirementYear === 0) {
              // bei neueem BP ist retirementYear unten umständen 0, dehalb nochmal in den wphg datan schauen
              let wphg = JSON.parse(JSON.parse(product.wphg));
              //console.log(wphg)
              year = wphg.user_choice_years_till_no_income;
            }
          } else if (
            roboData &&
            roboData.userData &&
            roboData.userData.user_choice_years_till_no_income
          ) {
            year = roboData.userData.user_choice_years_till_no_income;
          }
          nameAnlagerichtlinie =
            "Anlagerichtlinie  Altersvorsorge " +
            (helperGlobal.getRentenalter(year) + ", " + arrStrategy[0]);
          let nowYear = new Date().getFullYear();
          year = helperGlobal.getRentenalter(year) - nowYear; // doceument request needs this
          break;
        default:
          console.log("not defined " + category + "");
      }
    }

    let todoDetails = {
      type: "anlagerichtlinien",
      condition: conditionId,
      strategy: arrStrategy[0],
      years_till_no_income: year
    };

    let linkUrl =
      helperGlobal.getRestApiUrl() +
      "document/?" +
      Object.keys(todoDetails)
        .map(key => key + "=" + todoDetails[key])
        .join("&");
    //console.log('linkUrl',linkUrl)
    if (linkText) {
      return (
        <span
          className="link"
          onClick={() => helperGlobal.openCostsheetDocument(linkUrl)}
        >
          {linkText}
        </span>
      );
    } else {
      return (
        <span
          className="link"
          onClick={() => helperGlobal.openCostsheetDocument(linkUrl)}
        >
          {nameAnlagerichtlinie}
        </span>
      );
    }
  },
  getCostTextAtRegi(
    depot = false,
    addCase = false,
    stateVars = false,
    propsVars = false
  ) {
    //console.log(depot,addCase)
    //console.log(stateVars,propsVars)
    if (
      !addCase &&
      depot &&
      depot.products &&
      depot.products.items &&
      depot.products.items[0]
    ) {
      let product = depot.products.items[0];
      if (
        product.conditions &&
        product.conditions.items &&
        product.conditions.items[0] &&
        product.conditions.items[0].condition
      ) {
        let condition = product.conditions.items[0].condition;
        let amount_savingsplan = 0;

        if (
          product.savingsPlans &&
          product.savingsPlans.items &&
          product.savingsPlans.items[0]
        ) {
          amount_savingsplan = product.savingsPlans.items[0].rate;
        }

        let strategy = false;
        let years_till_no_income = 0;
        if (product.category === "AV.AV") {
          let wphg = JSON.parse(product.wphg);

          if (typeof wphg === "string") {
            wphg = JSON.parse(wphg);
          }

          if (wphg && wphg.user_choice_years_till_no_income) {
            years_till_no_income = wphg.user_choice_years_till_no_income;
          }
          strategy = product.strategy;
        } else {
          strategy = product.strategy.split(":")[0];
        }

        let thisYear = new Date().getFullYear();
        let correctStrategyYear =
          helperGlobal.getRentenalter(years_till_no_income) - thisYear;

        let todoDetails = {
          type: "costsheet",
          condition: condition.id,
          strategy: strategy,
          amount_savingsplan: amount_savingsplan,
          summe: product.wphgInitialAmount,
          years_till_no_income: correctStrategyYear
        };

        let linkUrl =
          helperGlobal.getRestApiUrl() +
          "document/?" +
          Object.keys(todoDetails)
            .map(key => key + "=" + todoDetails[key])
            .join("&");

        if (parseInt(condition.allowance) > 0) {
          return (
            <div className="costLink">
              Wir verwalten die ersten{" "}
              {helperBank.formatMoney(condition.allowance, 2)} € Ihrer Anlage
              bei quirion kostenfrei, darüber hinaus betragen die Kosten{" "}
              {helperBank.formatMoney(condition.feeQuirion, 2)} % p. a. des
              Anlagevolumens. Eine detaillierte Kostenaufstellung gemäß § 63
              Absatz 7 Wertpapierhandelsgesetz stellen wir Ihnen{" "}
              <span
                className="link"
                onClick={() => helperGlobal.openCostsheetDocument(linkUrl)}
              >
                hier
              </span>{" "}
              zur Verfügung.
            </div>
          );
        } else {
          return (
            <div className="costLink">
              Die Kosten für die Verwaltung Ihrer Anlage bei quirion betragen{" "}
              {helperBank.formatMoney(condition.feeQuirion, 2)} % p. a. des
              Anlagevolumens. Eine detaillierte Kostenaufstellung gemäß § 63
              Absatz 7 Wertpapierhandelsgesetz stellen wir Ihnen{" "}
              <span
                className="link"
                onClick={() => helperGlobal.openCostsheetDocument(linkUrl)}
              >
                hier
              </span>{" "}
              zur Verfügung.
            </div>
          );
        }
      }
    } else if (addCase && propsVars) {
      // addcase
      let userData = propsVars.roboData.userData;
      // testing add BP AV
      //console.log(userData,userData.user_choice_condition_id.indexOf('AV.AV'))
      // reloadproblem
      if (
        !userData.user_choice_condition_id &&
        !userData.user_choice_strategy_key
      ) {
        return "<div></div>";
      }

      let strategy = false;
      let years_till_no_income = 0;
      if (userData.user_choice_condition_id.indexOf("AV.AV") > -1) {
        let thisYear = new Date().getFullYear();
        years_till_no_income =
          helperGlobal.getRentenalter(
            userData.user_choice_years_till_no_income
          ) - thisYear;
        strategy = userData.user_choice_strategy_key;
      } else {
        strategy = userData.user_choice_strategy_key.split(":")[0];
      }

      let condition = false;
      for (let i = 0; i < propsVars.conditions.length; i++) {
        if (propsVars.conditions[i].id === userData.user_choice_condition_id) {
          condition = propsVars.conditions[i];
        }
      }

      let todoDetails = {
        type: "costsheet",
        condition: userData.user_choice_condition_id,
        strategy: strategy,
        amount_savingsplan: userData.user_choice_amount_savingsplan,
        summe: userData.user_choice_amount,
        years_till_no_income: years_till_no_income
      };

      let linkUrl =
        helperGlobal.getRestApiUrl() +
        "document/?" +
        Object.keys(todoDetails)
          .map(key => key + "=" + todoDetails[key])
          .join("&");

      if (condition) {
        if (parseInt(condition.allowance) > 0) {
          return (
            <div className="costLink">
              Wir verwalten die ersten{" "}
              {helperBank.formatMoney(condition.allowance, 2)} € Ihrer Anlage
              bei quirion kostenfrei, darüber hinaus betragen die Kosten{" "}
              {helperBank.formatMoney(condition.feeQuirion, 2)} % p. a. des
              Anlagevolumens. Eine detaillierte Kostenaufstellung gemäß § 63
              Absatz 7 Wertpapierhandelsgesetz stellen wir Ihnen{" "}
              <span
                className="link"
                onClick={() => helperGlobal.openCostsheetDocument(linkUrl)}
              >
                hier
              </span>{" "}
              zur Verfügung.
            </div>
          );
        } else {
          return (
            <div className="costLink">
              Die Kosten für die Verwaltung Ihrer Anlage bei quirion betragen{" "}
              {helperBank.formatMoney(condition.feeQuirion, 2)} % p. a. des
              Anlagevolumens. Eine detaillierte Kostenaufstellung gemäß § 63
              Absatz 7 Wertpapierhandelsgesetz stellen wir Ihnen{" "}
              <span
                className="link"
                onClick={() => helperGlobal.openCostsheetDocument(linkUrl)}
              >
                hier
              </span>{" "}
              zur Verfügung.
            </div>
          );
        }
      }
    } else {
      // sollte nicht vorkommen
      return <div></div>;
    }
  },
  getRentenalter(eintrittsjahr) {
    var year = new Date().getFullYear();
    var alter = Number(eintrittsjahr) + year;
    if (alter >= 2068) return "2070";
    else if (alter <= 2067 && alter >= 2063) return "2065";
    else if (alter <= 2062 && alter >= 2058) return "2060";
    else if (alter <= 2057 && alter >= 2053) return "2055";
    else if (alter <= 2052 && alter >= 2048) return "2050";
    else if (alter <= 2047 && alter >= 2044) return "2045";
    else if (alter <= 2043 && alter >= 2041) return "2042";
    else if (alter <= 2040 && alter >= 2038) return "2039";
    else if (alter <= 2037 && alter >= 2035) return "2036";
    else if (alter <= 2034 && alter >= 2032) return "2033";
    else if (alter <= 2031 && alter >= 2029) return "2030";
    else if (alter <= 2029 && alter >= 2027) return "2028";
    else if (alter <= 2027 && alter >= 2025) return "2026";
    else if (alter <= 2025 && alter >= 2023) return "2024";
    else if (alter <= 2023 && alter >= 2021) return "2022";
    else if (alter <= 2021 && alter >= 2020) return "2020";
    else if (alter === 2019) return "2019";
    else return "2070";
  }
};

export default withRouter(helperGlobal);
