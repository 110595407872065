//import React from 'react';
import { withRouter } from "react-router-dom";
//import { browserHistory } from 'react-router';

export function checkBic(value) {
  var bicRegexp = /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3}))/g;
  if (!bicRegexp.test(value.toUpperCase())) {
    return false;
  } else {
    return true;
  }
}

export function checkIban(value) {
  // Remove spaces and to upper case
  var iban = value.replace(/ /g, "").toUpperCase(),
    ibancheckdigits = "",
    leadingZeroes = true,
    cRest = "",
    cOperator = "",
    countrycode,
    ibancheck,
    charAt,
    cChar,
    bbanpattern,
    bbancountrypatterns,
    ibanregexp,
    i,
    p;

  // Check for IBAN code length.
  // It contains:
  // country code ISO 3166-1 - two letters,
  // two check digits,
  // Basic Bank Account Number (BBAN) - up to 30 chars
  var minimalIBANlength = 5;
  if (iban.length < minimalIBANlength) {
    return false;
  }

  // Check the country code and find the country specific format
  countrycode = iban.substring(0, 2);
  bbancountrypatterns = {
    AL: "\\d{8}[\\dA-Z]{16}",
    AD: "\\d{8}[\\dA-Z]{12}",
    AT: "\\d{16}",
    AZ: "[\\dA-Z]{4}\\d{20}",
    BE: "\\d{12}",
    BH: "[A-Z]{4}[\\dA-Z]{14}",
    BA: "\\d{16}",
    BR: "\\d{23}[A-Z][\\dA-Z]",
    BG: "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
    CR: "\\d{17}",
    HR: "\\d{17}",
    CY: "\\d{8}[\\dA-Z]{16}",
    CZ: "\\d{20}",
    DK: "\\d{14}",
    DO: "[A-Z]{4}\\d{20}",
    EE: "\\d{16}",
    FO: "\\d{14}",
    FI: "\\d{14}",
    FR: "\\d{10}[\\dA-Z]{11}\\d{2}",
    GE: "[\\dA-Z]{2}\\d{16}",
    DE: "\\d{18}",
    GI: "[A-Z]{4}[\\dA-Z]{15}",
    GR: "\\d{7}[\\dA-Z]{16}",
    GL: "\\d{14}",
    GT: "[\\dA-Z]{4}[\\dA-Z]{20}",
    HU: "\\d{24}",
    IS: "\\d{22}",
    IE: "[\\dA-Z]{4}\\d{14}",
    IL: "\\d{19}",
    IT: "[A-Z]\\d{10}[\\dA-Z]{12}",
    KZ: "\\d{3}[\\dA-Z]{13}",
    KW: "[A-Z]{4}[\\dA-Z]{22}",
    LV: "[A-Z]{4}[\\dA-Z]{13}",
    LB: "\\d{4}[\\dA-Z]{20}",
    LI: "\\d{5}[\\dA-Z]{12}",
    LT: "\\d{16}",
    LU: "\\d{3}[\\dA-Z]{13}",
    MK: "\\d{3}[\\dA-Z]{10}\\d{2}",
    MT: "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
    MR: "\\d{23}",
    MU: "[A-Z]{4}\\d{19}[A-Z]{3}",
    MC: "\\d{10}[\\dA-Z]{11}\\d{2}",
    MD: "[\\dA-Z]{2}\\d{18}",
    ME: "\\d{18}",
    NL: "[A-Z]{4}\\d{10}",
    NO: "\\d{11}",
    PK: "[\\dA-Z]{4}\\d{16}",
    PS: "[\\dA-Z]{4}\\d{21}",
    PL: "\\d{24}",
    PT: "\\d{21}",
    RO: "[A-Z]{4}[\\dA-Z]{16}",
    SM: "[A-Z]\\d{10}[\\dA-Z]{12}",
    SA: "\\d{2}[\\dA-Z]{18}",
    RS: "\\d{18}",
    SK: "\\d{20}",
    SI: "\\d{15}",
    ES: "\\d{20}",
    SE: "\\d{20}",
    CH: "\\d{5}[\\dA-Z]{12}",
    TN: "\\d{20}",
    TR: "\\d{5}[\\dA-Z]{17}",
    AE: "\\d{3}\\d{16}",
    GB: "[A-Z]{4}\\d{14}",
    VG: "[\\dA-Z]{4}\\d{16}"
  };

  bbanpattern = bbancountrypatterns[countrycode];

  // As new countries will start using IBAN in the
  // future, we only check if the countrycode is known.
  // This prevents false negatives, while almost all
  // false positives introduced by this, will be caught
  // by the checksum validation below anyway.
  // Strict checking should return FALSE for unknown
  // countries.
  if (typeof bbanpattern !== "undefined") {
    ibanregexp = new RegExp("^[A-Z]{2}\\d{2}" + bbanpattern + "$", "");
    if (!ibanregexp.test(iban)) {
      return false; // Invalid country specific format
    }
  }

  // Now check the checksum, first convert to digits
  ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4);
  for (i = 0; i < ibancheck.length; i++) {
    charAt = ibancheck.charAt(i);
    if (charAt !== "0") {
      leadingZeroes = false;
    }
    if (!leadingZeroes) {
      ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(charAt);
    }
  }

  // Calculate the result of: ibancheckdigits % 97
  for (p = 0; p < ibancheckdigits.length; p++) {
    cChar = ibancheckdigits.charAt(p);
    cOperator = "" + cRest + "" + cChar;
    cRest = cOperator % 97;
  }
  return cRest === 1;
}

const helperBank = {
  getMyBankAccounts: (
    depots,
    types = [],
    bpId = null,
    withDuplicates = false
  ) => {
    let bankAccounts = [];
    // types can be > ['saving','debit','reference','clearing']
    if (depots !== undefined) {
      depots.forEach(function(depot) {
        if (bpId !== null) {
          // eslint-disable-next-line
          if (bpId == depot.id) {
            depot.bankAccounts.items.forEach(function(bankAccount) {
              if (
                types.indexOf(bankAccount.accountType) > -1 ||
                types.length <= 0
              ) {
                if (withDuplicates) {
                  bankAccounts.push(bankAccount);
                } else {
                  let ibanAllreadyExists = false;
                  bankAccounts.forEach(function(existingBankAccount) {
                    if (
                      existingBankAccount.iban.replace(/ /g, "") ===
                      bankAccount.iban.replace(/ /g, "")
                    ) {
                      ibanAllreadyExists = true;
                    }
                  });
                  if (ibanAllreadyExists === false) {
                    bankAccounts.push(bankAccount);
                  }
                }
              }
            });
            depot.products.items.forEach(function(product) {
              if (product.savingsPlans) {
                product.savingsPlans.items.forEach(function(savingsPlan) {
                  if (savingsPlan.bankAccount) {
                    if (
                      types.indexOf(savingsPlan.bankAccount.accountType) > -1 ||
                      types.length <= 0
                    ) {
                      if (withDuplicates) {
                        bankAccounts.push(savingsPlan.bankAccount);
                      } else {
                        let ibanAllreadyExists = false;
                        bankAccounts.forEach(function(bankAccount) {
                          if (
                            bankAccount.iban.replace(/ /g, "") ===
                            savingsPlan.bankAccount.iban.replace(/ /g, "")
                          ) {
                            ibanAllreadyExists = true;
                          }
                        });
                        if (ibanAllreadyExists === false) {
                          bankAccounts.push(savingsPlan.bankAccount);
                        }
                      }
                    }
                  }
                });
              }
            });
          }
        } else {
          depot.bankAccounts.items.forEach(function(bankAccount) {
            if (
              types.indexOf(bankAccount.accountType) > -1 ||
              types.length <= 0
            ) {
              if (withDuplicates) {
                bankAccounts.push(bankAccount);
              } else {
                let ibanAllreadyExists = false;
                bankAccounts.forEach(function(existingBankAccount) {
                  if (
                    existingBankAccount.iban.replace(/ /g, "") ===
                    bankAccount.iban.replace(/ /g, "")
                  ) {
                    ibanAllreadyExists = true;
                  }
                });
                if (ibanAllreadyExists === false) {
                  bankAccounts.push(bankAccount);
                }
              }
            }
          });
          depot.products.items.forEach(function(product) {
            if (product.savingsPlans) {
              product.savingsPlans.items.forEach(function(savingsPlan) {
                if (savingsPlan.bankAccount) {
                  if (
                    types.indexOf(savingsPlan.bankAccount.accountType) > -1 ||
                    types.length <= 0
                  ) {
                    if (withDuplicates) {
                      bankAccounts.push(savingsPlan.bankAccount);
                    } else {
                      let ibanAllreadyExists = false;
                      bankAccounts.forEach(function(bankAccount) {
                        if (
                          bankAccount.iban.replace(/ /g, "") ===
                          savingsPlan.bankAccount.iban.replace(/ /g, "")
                        ) {
                          ibanAllreadyExists = true;
                        }
                      });
                      if (ibanAllreadyExists === false) {
                        bankAccounts.push(savingsPlan.bankAccount);
                      }
                    }
                  }
                }
              });
            }
          });
        }
      });
    }
    return bankAccounts;
  },
  getLiquidAccountByProduct(depots, productID) {
    let iban = "";
    depots.forEach(function(depot) {
      depot.products.items.forEach(function(product) {
        if (product.id === productID) {
          iban = product.liquidAccounts.items[0].iban;
        }
      });
    });
    return iban;
  },
  getPreSign: arg => {
    if (arg < 0) {
      return "";
    } else {
      return "+";
    }
  },
  formatMoney: (arg, toFixed = 2, cutCents = false, toLocal = true) => {
    // we round(toFixed)  only if cutCents = false

    let returnValue = arg;

    if (cutCents) {
      returnValue = Math.trunc(returnValue);
    } else {
      returnValue = Number.parseFloat(returnValue).toFixed(toFixed);
    }

    if (toLocal) {
      return Number(returnValue).toLocaleString("de-DE", {
        minimumFractionDigits: toFixed,
        maximumFractionDigits: toFixed
      });
    } else {
      return returnValue;
    }
  },
  formatIban: arg => {
    if (arg) {
      arg = arg.replace(" ", "");
      arg = arg.substr(0, 20) + " " + arg.substr(20);
      arg = arg.substr(0, 16) + " " + arg.substr(16);
      arg = arg.substr(0, 12) + " " + arg.substr(12);
      arg = arg.substr(0, 8) + " " + arg.substr(8);
      arg = arg.substr(0, 4) + " " + arg.substr(4);
      arg = arg.trim().toUpperCase();
    }
    return arg;
  },
  checkBic,
  checkTin: tin => {
    tin = tin.replace(/ /g, "");
    tin = tin.replace(/\//g, "");

    let sum = 0;
    let product = 10;
    for (let i = 0; i < 10; i++) {
      const c = tin.charAt(i);
      sum = (c - "0" + product) % 10;
      // eslint-disable-next-line
      if (sum == 0) sum = 10;
      product = (sum * 2) % 11;
    }
    let check = 11 - product;
    // eslint-disable-next-line
    if (check == 10) check = 0;
    //console.log(tin.length+'-'+tin.charAt(10)+'-'+check)
    // eslint-disable-next-line
    if (tin.length == 11 && tin.charAt(10) == check) {
      return true;
    } else {
      return false;
    }
  },
  checkIban
};

export default withRouter(helperBank);
