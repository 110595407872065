export default [
  { DEU: "Deutschland" },
  { AFG: "Afghanistan" },
  { ALB: "Albanien" },
  { DZA: "Algerien" },
  { AND: "Andorra" },
  { AGO: "Angola" },
  { ATG: "Antigua und Barbuda" },
  { ARG: "Argentinien" },
  { ARM: "Armenien" },
  { AZE: "Aserbaidschan" },
  { AUS: "Australien" },
  { BHS: "Bahamas" },
  { BHR: "Bahrain" },
  { BGD: "Bangladesch" },
  { BRB: "Barbados" },
  { BLR: "Belarus (Weißrussland)" },
  { BEL: "Belgien" },
  { BLZ: "Belize" },
  { BEN: "Benin" },
  { BMU: "Bermuda" },
  { BTN: "Bhutan" },
  { BOL: "Bolivien" },
  { BIH: "Bosnien und Herzegowina" },
  { BWA: "Botswana" },
  { BRA: "Brasilien" },
  { VGB: "Britische Jungferninseln" },
  { BRN: "Brunei Darussalam" },
  { BGR: "Bulgarien" },
  { BFA: "Burkina Faso" },
  { BDI: "Burundi" },
  { CHL: "Chile" },
  { CHN: "China, Volksrepublik" },
  { COK: "Cookinseln" },
  { CRI: "Costa Rica" },
  { CIV: "Côte d’Ivoire (Elfenbeinküste)" },
  { DMA: "Dominica" },
  { DOM: "Dominikanische Republik" },
  { DJI: "Dschibuti" },
  { DNK: "Dänemark" },
  { ECU: "Ecuador" },
  { SLV: "El Salvador" },
  { ERI: "Eritrea" },
  { EST: "Estland" },
  { FJI: "Fidschi" },
  { FIN: "Finnland" },
  { FRA: "Frankreich" },
  { GAB: "Gabun" },
  { GMB: "Gambia" },
  { GEO: "Georgien" },
  { GHA: "Ghana" },
  { GRD: "Grenada" },
  { GRC: "Griechenland" },
  { GTM: "Guatemala" },
  { GIN: "Guinea" },
  { GNB: "Guinea-Bissau" },
  { GUY: "Guyana" },
  { HTI: "Haiti" },
  { HND: "Honduras" },
  { HKG: "Hongkong" },
  { IND: "Indien" },
  { IDN: "Indonesien" },
  { IRQ: "Irak" },
  { IRN: "Iran, Islamische Republik" },
  { IRL: "Irland" },
  { ISL: "Island" },
  { ISR: "Israel" },
  { ITA: "Italien" },
  { JAM: "Jamaika" },
  { JPN: "Japan" },
  { YEM: "Jemen" },
  { JOR: "Jordanien" },
  { KHM: "Kambodscha" },
  { CMR: "Kamerun" },
  { CAN: "Kanada" },
  { CPV: "Kap Verde" },
  { KAZ: "Kasachstan" },
  { QAT: "Katar" },
  { KEN: "Kenia" },
  { KGZ: "Kirgisistan" },
  { KIR: "Kiribati" },
  { COL: "Kolumbien" },
  { COM: "Komoren" },
  { COD: "Kongo, Demokratische Republik (ehem. Zaire)" },
  { PRK: "Korea, Demokratische Volksrepublik (Nordkorea)" },
  { KOR: "Korea, Republik (Südkorea)" },
  { HRV: "Kroatien" },
  { CUB: "Kuba" },
  { KWT: "Kuwait" },
  { LAO: "Laos, Demokratische Volksrepublik" },
  { LSO: "Lesotho" },
  { LVA: "Lettland" },
  { LBN: "Libanon" },
  { LBR: "Liberia" },
  { LBY: "Libyen" },
  { LIE: "Liechtenstein" },
  { LTU: "Litauen" },
  { LUX: "Luxemburg" },
  { MDG: "Madagaskar" },
  { MWI: "Malawi" },
  { MYS: "Malaysia" },
  { MDV: "Malediven" },
  { MLI: "Mali" },
  { MLT: "Malta" },
  { MAR: "Marokko" },
  { MHL: "Marshallinseln" },
  { MRT: "Mauretanien" },
  { MUS: "Mauritius" },
  { MKD: "Mazedonien" },
  { MEX: "Mexiko" },
  { FSM: "Mikronesien" },
  { MDA: "Moldawien (Republik Moldau)" },
  { MCO: "Monaco" },
  { MNG: "Mongolei" },
  { MNE: "Montenegro" },
  { MOZ: "Mosambik" },
  { MMR: "Myanmar (Burma)" },
  { NAM: "Namibia" },
  { NRU: "Nauru" },
  { NPL: "Nepal" },
  { NZL: "Neuseeland" },
  { NIC: "Nicaragua" },
  { NLD: "Niederlande" },
  { NER: "Niger" },
  { NGA: "Nigeria" },
  { NIU: "Niue" },
  { NOR: "Norwegen" },
  { OMN: "Oman" },
  { TLS: "Osttimor (Timor-Leste)" },
  { PAK: "Pakistan" },
  { PLW: "Palau" },
  { PAN: "Panama" },
  { PNG: "Papua-Neuguinea" },
  { PRY: "Paraguay" },
  { PER: "Peru" },
  { PHL: "Philippinen" },
  { POL: "Polen" },
  { PRT: "Portugal" },
  { TWN: "Republik China (Taiwan)" },
  { COG: "Republik Kongo" },
  { RWA: "Ruanda" },
  { ROU: "Rumänien" },
  { RUS: "Russische Föderation" },
  { SLB: "Salomonen" },
  { ZMB: "Sambia" },
  { WSM: "Samoa" },
  { SMR: "San Marino" },
  { STP: "São Tomé und Príncipe" },
  { SAU: "Saudi-Arabien" },
  { SWE: "Schweden" },
  { CHE: "Schweiz (Confoederatio Helvetica)" },
  { SEN: "Senegal" },
  { SRB: "Serbien" },
  { SYC: "Seychellen" },
  { SLE: "Sierra Leone" },
  { ZWE: "Simbabwe" },
  { SGP: "Singapur" },
  { SVK: "Slowakei" },
  { SVN: "Slowenien" },
  { SOM: "Somalia" },
  { ESP: "Spanien" },
  { LKA: "Sri Lanka" },
  { KNA: "St. Kitts und Nevis" },
  { LCA: "St. Lucia" },
  { VCT: "St. Vincent und die Grenadinen" },
  { PSE: "Staat Palästina[5]" },
  { SDN: "Sudan" },
  { SUR: "Suriname" },
  { SWZ: "Swasiland" },
  { ZAF: "Südafrika" },
  { SSD: "Südsudan" },
  { SYR: "Syrien, Arabische Republik" },
  { TJK: "Tadschikistan" },
  { TZA: "Tansania, Vereinigte Republik" },
  { THA: "Thailand" },
  { TGO: "Togo" },
  { TON: "Tonga" },
  { TTO: "Trinidad und Tobago" },
  { TCD: "Tschad" },
  { CZE: "Tschechische Republik" },
  { TUN: "Tunesien" },
  { TKM: "Turkmenistan" },
  { TUV: "Tuvalu" },
  { TUR: "Türkei" },
  { UGA: "Uganda" },
  { UKR: "Ukraine" },
  { HUN: "Ungarn" },
  { URY: "Uruguay" },
  { UZB: "Usbekistan" },
  { VUT: "Vanuatu" },
  { VAT: "Vatikanstadt" },
  { VEN: "Venezuela" },
  { ARE: "Vereinigte Arabische Emirate" },
  { USA: "Vereinigte Staaten von Amerika" },
  { GBR: "Vereinigtes Königreich Großbritannien und Nordirland" },
  { VNM: "Vietnam" },
  { ESH: "Westsahara" },
  { CAF: "Zentralafrikanische Republik" },
  { CYP: "Zypern" },
  { EGY: "Ägypten" },
  { GNQ: "Äquatorialguinea" },
  { ETH: "Äthiopien" },
  { AUT: "Österreich" }
];
