import React from "react";

export default {
  quirion_home: {
    url: "http://www.quirion.de/",
    target: "_blank"
  },
  quirion_impressum: {
    url: "https://www.quirion.de/impressum/",
    target: "_blank",
    title: "Impressum der Qurion AG"
  },
  quirion_twr_tooltip: {
    url: "https://www.quirion.de/wie-berechnen-wir-die-rendite/",
    target: "_blank",
    title: (
      <span>
        Wie wir Ihre Rendite im Detail berechnen, erfahren Sie{" "}
        <a
          href="https://www.quirion.de/wie-berechnen-wir-die-rendite/"
          target="_blank"
          rel="noopener noreferrer"
        >
          hier
        </a>
        .
      </span>
    )
  },
  quirion_datenschutz: {
    url: "https://www.quirion.de/datenschutz/",
    target: "_blank",
    title: "Datenschutzerklärung"
  },
  quirin_datenschutz: {
    url: "https://www.quirinprivatbank.de/datenschutz",
    target: "_blank",
    title: "Datenschutzerklärung"
  }
};
