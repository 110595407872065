// @flow
import "react-hot-loader";
import { hot } from "react-hot-loader/root";
import React, { Component } from "react";
//import Amplify,{ Auth,API,graphqlOperation,Storage }  from "aws-amplify"
import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";
import Cache from "@aws-amplify/cache";
import Analytics from "@aws-amplify/analytics";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
//import { Link,NavLink,withRouter } from 'react-router-dom'
import helperGlobal from "./utils/helper_global";
import Routes from "./components/Router";
import {
  updateUserLastActivity,
  loadPerson,
  loadUnreadedPostBox,
  loadUser,
  loadConditions,
  addMessage
} from "./actions/userActions";
import { loadHistoricData } from "./actions/roboActions";
import axios from "axios";
import aws_config_dev from "./../aws-exports-dev";
import aws_config_test from "./../aws-exports-test";
import aws_config_prod from "./../aws-exports-prod";
import { getPersonPostbox, getPerson } from "./../graphql/queries_own";
import { updatePerson, identityId } from "./../graphql/mutations";
import {
  onUpdatePerson,
  onUpdateBusinessPartner,
  onUpdatePersonBusinessPartnerMap,
  onCreatePersonBusinessPartnerMap,
  onUpdateBusinessPartnerCode,
  onCreateBusinessPartnerCode,
  onCreateSavingsPlan,
  onDeleteSavingsPlan,
  onCreateOrder,
  onUpdateOrder
} from "./../graphql/subscriptions";

require("dotenv").config();

let aws_config = aws_config_dev;

if (process.env.NODE_ENV && process.env.REACT_APP_BRANCH === "master") {
  aws_config = aws_config_prod;
} else if (process.env.NODE_ENV && process.env.REACT_APP_BRANCH === "test") {
  aws_config = aws_config_test;
} else {
  // Disabled Amplify Analytics helps to avoid an Error during running local setup with 'npm start'.
  // Error: Unhandled Rejection (Error): No credentials, applicationId or region
  // AWS PinPoint Provider
  // For AWS Amplify to work properly, make sure you are working inside a valid amplify project. 
  // Use 'amplify init' in the root of your app directory to initialize your project with Amplify.
  // Also see this guide (WIP): https://www.notion.so/README-AWS-Amplify-setup-486359bdaf6c49ed8bee65c847d4248d
  // Also see Disabled Analytics in the Docs: https://aws-amplify.github.io/docs/js/analytics#disable-analytics
  Analytics.disable();
}

if (
  window.location &&
  window.location.hostname &&
  window.location.hostname === "localhost"
) {
  //aws_config = aws_config_prod

  if (aws_config === aws_config_prod) {
    console.log("################# ACHTUNG PROD");
  }
}

Amplify.configure(aws_config);
Cache.configure();

const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    // appId: process.env.REACT_APP_PINPOINT_ID,
    appId: aws_config.aws_mobile_analytics_app_id,
    // Amazon service region
    region: aws_config.aws_mobile_analytics_app_region,
    mandatorySignIn: false
  }
};

// Only configure analytics in prod
aws_config_prod && Analytics.configure(analyticsConfig);

class Root extends Component {
  state = {
    user: {}
  };

  static intervalHAndle;
  tick = this.tick.bind(this);

  static key = 1;

  setKeys(questions) {
    for (var prop in questions) {
      if (
        prop === "type" &&
        (questions[prop] === "standard" ||
          questions[prop] === "selection" ||
          questions[prop] === "choice" ||
          questions[prop] === "finish" ||
          questions[prop] === "risiko_praeferenz" ||
          questions[prop] === "number" ||
          questions[prop] === "promise" ||
          questions[prop] === "create_demo" ||
          questions[prop] === "create_account" ||
          questions[prop] === "confirm_account")
      ) {
        questions.key = Root.key;
        Root.key++;
      }
      if (prop === "options") {
        for (var prop2 in questions[prop]) {
          if (questions[prop][prop2]["go_on"]) {
            for (var i = 0; i < questions[prop][prop2]["go_on"].length; i++) {
              questions[prop][prop2]["go_on"][i] = this.setKeys(
                questions[prop][prop2]["go_on"][i]
              );
            }
          }
        }
      }
    }
    return questions;
  }

  async loadUnreadedPostBoxLocal(personId) {
    const todoDetails = {
      id: personId,
      limit: 10000,
      filter: {
        and: {
          readTime: { notContains: "T" }
        }
      }
    };
    let responsePostbox = await API.graphql(
      graphqlOperation(getPersonPostbox, todoDetails)
    );
    this.props.loadUnreadedPostBox(responsePostbox);

    return responsePostbox;
  }

  async loadPersonLocal(personId, user) {
    // get
    const todoDetails = {
      id: personId
    };
    let response = await API.graphql(graphqlOperation(getPerson, todoDetails));
    this.props.loadPerson(response);
    this.setIdentityId(user, response);
    response.data.getPerson.needToMigrateDoc && this.migrateDoc(user);

    const todoDetailsSubscriptions = {
      owner: personId
    };
    // subscribe
    this.state.subscription_onUpdatePerson = API.graphql(
      graphqlOperation(onUpdatePerson, todoDetailsSubscriptions)
    ).subscribe({
      next: eventData => {
        console.log("########## onUpdatePerson", eventData);
        this.syncData(personId);
        //this.props.loadPerson(eventData)
      }
    });

    this.state.subscription_onUpdateBusinessPartner = API.graphql(
      graphqlOperation(onUpdateBusinessPartner, todoDetailsSubscriptions)
    ).subscribe({
      next: eventData => {
        console.log("########## onUpdateBusinessPartner", eventData);
        this.syncData(personId);
        //this.props.loadPerson(eventData)
      }
    });

    this.state.subscription_onUpdatePersonBusinessPartnerMap = API.graphql(
      graphqlOperation(
        onUpdatePersonBusinessPartnerMap,
        todoDetailsSubscriptions
      )
    ).subscribe({
      next: eventData => {
        console.log(
          "########## onUpdatePersonBusinessPartnerMap",
          eventData,
          "lade die person erneut"
        );
      }
    });

    this.state.subscription_onCreatePersonBusinessPartnerMap = API.graphql(
      graphqlOperation(
        onCreatePersonBusinessPartnerMap,
        todoDetailsSubscriptions
      )
    ).subscribe({
      next: eventData => {
        console.log(
          "########## onCreatePersonBusinessPartnerMap",
          eventData,
          "lade die person erneut"
        );
      }
    });

    this.state.subscription_onUpdateBusinessPartnerCode = API.graphql(
      graphqlOperation(onUpdateBusinessPartnerCode, todoDetailsSubscriptions)
    ).subscribe({
      next: eventData => {
        console.log(
          "########## OnUpdateBusinessPartnerCode",
          eventData,
          "lade die person erneut"
        );
      }
    });

    this.state.subscription_onCreateBusinessPartnerCode = API.graphql(
      graphqlOperation(onCreateBusinessPartnerCode, todoDetailsSubscriptions)
    ).subscribe({
      next: eventData => {
        console.log(
          "########## onCreateBusinessPartnerCode",
          eventData,
          "lade die person erneut"
        );
      }
    });

    this.state.subscription_onCreateSavingsPlan = API.graphql(
      graphqlOperation(onCreateSavingsPlan, todoDetailsSubscriptions)
    ).subscribe({
      next: eventData => {
        console.log(
          "########## onCreateSavingsPlan",
          eventData,
          "lade die person erneut"
        );
        this.syncData(personId);
      }
    });

    this.state.subscription_onDeleteSavingsPlan = API.graphql(
      graphqlOperation(onDeleteSavingsPlan, todoDetailsSubscriptions)
    ).subscribe({
      next: eventData => {
        console.log(
          "########## onDeleteSavingsPlan",
          eventData,
          "lade die person erneut"
        );
        this.syncData(personId);
      }
    });

    this.state.subscription_onCreateOrder = API.graphql(
      graphqlOperation(onCreateOrder, todoDetailsSubscriptions)
    ).subscribe({
      next: eventData => {
        console.log(
          "########## onCreateOrder",
          eventData,
          "lade die uncussessed orders erneut"
        );
        this.loadUnSuccededOrders(personId);
      }
    });

    this.state.subscription_onUpdateOrder = API.graphql(
      graphqlOperation(onUpdateOrder, todoDetailsSubscriptions)
    ).subscribe({
      next: eventData => {
        console.log(
          "########## onUpdateOrder",
          eventData,
          "lade die uncussessed orders erneut"
        );
        this.loadUnSuccededOrders(personId);
      }
    });

    return response;
  }

  async syncData(personId) {
    const todoDetails = {
      id: personId
    };
    let response = await API.graphql(graphqlOperation(getPerson, todoDetails));
    this.props.loadPerson(response);
  }

  async setIdentityId(user, person) {
    if (
      person.data.getPerson.identityId === null ||
      person.data.getPerson.identityId.trim().length <= 0
    ) {
      for (let prop in user.pool.storage) {
        if (hasOwnProperty.call(user.pool.storage, prop)) {
          if (
            prop.indexOf(
              "aws.cognito.identity-id." +
                aws_config.aws_cognito_identity_pool_id
            ) === 0
          ) {
            const todoDetails = {
              input: { id: user.username, identityId: user.pool.storage[prop] }
            };
            await API.graphql(graphqlOperation(updatePerson, todoDetails));
          }
        }
      }
    }
  }

  // QUIK-2578
  async migrateDoc({ username, pool: { storage } }) {
    try {
      const identityIdValue = Object.entries(storage).find(
        prop =>
          prop[0] ===
          `aws.cognito.identity-id.${aws_config.aws_cognito_identity_pool_id}`
      )[1];
      await API.graphql(
        graphqlOperation(identityId, {
          personId: username,
          identityId: identityIdValue
        })
      );
    } catch (err) {
      console.error(`failed to mutate identityId`, err);
    }
  }

  getDateByTimestamp(unix_timestamp) {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unix_timestamp * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime =
      hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
    return formattedTime;
  }

  loadConditionsByStatus(user = null, advisor = "quirion") {
    /*
    if(user){
        axios.post( helperGlobal.getRestApiUrl()+ 'condition',{personId:user.username})
            .then( (response) => {
                this.props.loadConditions(response)
            }
        )
    }
    else{*/
    axios
      .post(helperGlobal.getRestApiUrl() + "condition", { advisor: advisor })
      .then(response => {
        this.props.loadConditions(response);
      });
    //}
  }

  setUserStatus = () => {
    let timeToBeValid = 60 * 15;

    if (
      window.location &&
      window.location.hostname &&
      window.location.hostname === "localhost"
    ) {
      timeToBeValid = 60 * 60 * 24;
    }

    let now = Math.floor(Date.now() / 1000);
    let lastActivity = localStorage.getItem("lastActivityAt");
    if (
      localStorage.getItem("lastActivityAt") &&
      parseInt(lastActivity) > 0 &&
      now - lastActivity > timeToBeValid
    ) {
      localStorage.setItem("lastActivityAt", 0);

      Auth.signOut()
        .then(data => (document.location.href = "/logout/byInacvtivity"))
        .catch(err => (document.location.href = "/logout/byInacvtivity"));

      return false;
    }

    Auth.currentAuthenticatedUser()
      .then(user => {
        let isAdmin = false;
        if (user.signInUserSession.accessToken.payload["cognito:groups"]) {
          user.signInUserSession.accessToken.payload["cognito:groups"].forEach(
            function(element) {
              if (element === "Admin") {
                // its a customer-service one
                isAdmin = true;
              }
            }
          );
        }
        if (isAdmin) {
          if (window.location.href.indexOf("/customer-service/") <= -1) {
            window.location.href = "https://quirion-kundenbetreuung.de";
          }
        } else {
          //console.log(user)
          //console.log('idToken',this.getDateByTimestamp(user.signInUserSession.idToken.payload.auth_time),this.getDateByTimestamp(user.signInUserSession.idToken.payload.exp))
          //console.log('accessToken',this.getDateByTimestamp(user.signInUserSession.accessToken.payload.auth_time),this.getDateByTimestamp(user.signInUserSession.accessToken.payload.exp))
          this.props.updateUserLastActivity();
          this.tick();
          this.intervalHandle = setInterval(this.tick, 10000);
          if (user !== this.state.user) {
            this.props.loadUser(user);
            this.loadPersonLocal(user.username, user);
            this.loadUnreadedPostBoxLocal(user.username);
            this.setState({ user: user });
            //this.loadConditionsByStatus(user)
          }
        }
      })
      .catch(err => {
        //this.loadConditionsByStatus()
        let loginPath = "/login/";
        let allowedPaths = [
          loginPath,
          "/logout/",
          "/robo/",
          "/robo",
          "/logout/byInacvtivity",
          "/forgot_password",
          "/forgot_password/"
        ];
        let allowedDynamicPaths = ["/user/activate/"];
        if (allowedPaths.indexOf(window.location.pathname) < 0) {
          // Current pathname doesn't match with a string in allowedPaths
          let foundAllowedDynamicPath = false;
          allowedDynamicPaths.forEach(function(element) {
            if (window.location.pathname.indexOf(element) >= 0 || window.location.pathname.includes("robo")) {
              foundAllowedDynamicPath = true;
            }
          });
          if (foundAllowedDynamicPath) {
            // allowed
          } else {
            window.location.href = loginPath;
          }
        }
      });
  };

  tick() {
    this.logoutIfUserWasInactive(this);
  }

  logoutIfUserWasInactive(dieses) {
    let maxSecondsInactiveIsOk = 60 * 15;

    if (
      window.location &&
      window.location.hostname &&
      window.location.hostname === "localhost"
    ) {
      maxSecondsInactiveIsOk = 60 * 60 * 24;
    }

    let now = Math.floor(Date.now() / 1000);
    let lastActivity = this.props.userStatus.lastActivityAt;
    //console.log(maxSecondsInactiveIsOk + '-' + ' (' + now + ' - ' + lastActivity +')')
    //console.log(maxSecondsInactiveIsOk - (now-lastActivity))
    if (now - lastActivity > maxSecondsInactiveIsOk) {
      Auth.signOut()
        .then(data => (document.location.href = "/logout/byInacvtivity"))
        .catch(err => (document.location.href = "/logout/byInacvtivity"));
    }
  }

  UNSAFE_componentWillMount() {
    const { questions } = this.props;
    this.setUserStatus();
    this.setState({ questions: this.setKeys(questions) }); // setze auf jede "frage" einen KEY
    //console.log( process.env)

    this.loadConditionsByStatus();

    axios.get(helperGlobal.getRestApiUrl() + "gap").then(response => {
      this.props.loadHistoricData("GAP", response);
    });
    /*
        axios.get('https://c3ofjha5c5.execute-api.eu-central-1.amazonaws.com/dev/nh',{headers: {'Authorization': "bearer "+ user.token}})
            .then( (response) => {
                this.props.loadHistoricData('NH',response)
            }
        )
        */
    axios.get(helperGlobal.getRestApiUrl() + "av").then(response => {
      this.props.loadHistoricData("AV", response);
    });
  }

  render() {
    const { user } = this.props;

    let loaderScreen = "";
    if (this.props.userStatus.showLoader) {
      loaderScreen = (
        <div className="loading" id="loading">
          <div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }

    var body = document.getElementsByTagName("body")[0];
    if (window.location.href.indexOf("/customer-service/") > -1) {
      body.classList.remove("hideMainBar");
    } else if (user.loaded) {
      body.classList.remove("hideMainBar");
    } else {
      // eslint-disable-next-line
      $("body").addClass("hideMainBar");
    }

    return (
      <BrowserRouter>
        <Routes />
        {loaderScreen}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    questions: state.questions,
    user: state.user,
    advisor: state.advisor,
    person: state.person,
    depots: state.depots,
    userStatus: state.userStatus,
    roboData: state.roboData
  };
};

Root.propTypes = {
  loadUser: PropTypes.func.isRequired,
  loadConditions: PropTypes.func.isRequired,
  loadPerson: PropTypes.func.isRequired,
  loadUnreadedPostBox: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  loadHistoricData: PropTypes.func.isRequired,
  updateUserLastActivity: PropTypes.func.isRequired
};

export default hot(
  connect(mapStateToProps, {
    updateUserLastActivity,
    loadUser,
    loadPerson,
    loadUnreadedPostBox,
    loadConditions,
    addMessage,
    loadHistoricData
  })(Root)
);
