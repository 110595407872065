/* eslint-disable */

//generate strategies base on type of account and user input

const strategy = [
  "10/90",
  "20/80",
  "30/70",
  "40/60",
  "50/50",
  "60/40",
  "70/30",
  "80/20",
  "90/10",
  "100/0"
];

const vola = [3.2, 4.7, 6.1, 8.1, 10.1, 12.3, 14.5, 16.7, 18.9, 21.2];

const percent = [14, 21, 27, 36, 45, 55, 65, 75, 85, 95];

/**
 * Returns the sum of current and following value in an array, divided by 2
 * @method
 * @param {[number]} arr - array of values
 * @param {number} val - value at arr[idx]
 * @param {number} idx - current index in array
 * @returns { number }  ( val + arr[ idx + 1 ] ) / 2
 */
const mapMath = (arr, val, idx) => (val + arr[idx + 1]) / 2;

/**
 * full mapping of strategies
 * @constant
 * @type {object}
 * @property {[object]} - strategy
 * @property {[object]} - vola
 * @property {[object]} - percent
 * @property {[object]} - longHold
 * @property {[object]} - mediumHold
 * @property {[object]} - shortHold
 */
export const mapping = {
  strategy: [...strategy],
  vola: [...vola],
  percent: [...percent],
  longHold: [...percent.map((curr, idx) => mapMath(percent, curr, idx))],
  mediumHold: [
    ...percent
      .map((curr, idx) => mapMath(percent, curr, idx))
      .map(each => Math.min(each + 10, 100))
  ],
  shortHold: [
    ...percent
      .map((curr, idx) => mapMath(percent, curr, idx))
      .map(each => Math.min(each + 20, 100))
  ]
};

/**
 * Returns a filtered array depending on the holding type
 * @method
 * @param {string} holding - holding period
 * @param {[]} mapping - refers to the mapping of strategies
 * @returns {{strategy:[]; vola:[]; percent:[]; map:[]}} filtered array based on holding
 */
const filterMapping = (holding, mapping) => {
  switch (holding) {
    case "short":
    default:
      return {
        strategy: [...mapping.strategy],
        vola: [...mapping.vola],
        percent: [...mapping.percent],
        map: [...mapping.shortHold]
      };
    case "medium":
      return {
        strategy: [...mapping.strategy],
        vola: [...mapping.vola],
        percent: [...mapping.percent],
        map: [...mapping.mediumHold]
      };
    case "long":
      return {
        strategy: [...mapping.strategy],
        vola: [...mapping.vola],
        percent: [...mapping.percent],
        map: [...mapping.longHold]
      };
    case "retirement":
      return { strategy: [...mapping.strategy], map: [...mapping.hold] };
  }
};

/*RETIREMENT STRATEGIES */

export const retirementMapping = {
  strategy: ["defensiv", "moderat", "offensiv"],
  hold: [30, 60]
};

/**
 * return the correct strategy based on userData
 * @exports
 * @method
 * @param {{}} userData - userData from reduc store
 * @param {{}} map - mapping object
 * @returns {{strategy:String}} strategy
 */
export const getCorrectStrategy = (userData, map) => {
  //console.log(userData)
  const mapping =
    userData.wphg_investment_purpose === "retirement"
      ? map.retirement
      : map.default;
  const holdingPeriod =
    userData.wphg_holding_period !== null
      ? userData.wphg_holding_period
      : "retirement";
  const finalResult = userData.wphg_final_choice.finalResult;
  //console.log('getCorrectStrategy',holdingPeriod, mapping)
  const filtered = filterMapping(holdingPeriod, mapping);
  let holdIndex = filtered.map.reduce(
    (acc, curr, idx) =>
      parseInt(finalResult) - curr > 0 ? (acc = idx + 1) : (acc = acc),
    0
  );
  return { strategy: filtered.strategy[holdIndex] };
};
